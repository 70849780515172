import {
	Box,
	Button,
	Divider,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	VStack,
	Image,
	Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type MicPermissionModalProps = {
	isOpen: boolean;
	onClose: () => void;
};

const MicPermissionModal = ({ isOpen, onClose }: MicPermissionModalProps) => {
	const { t } = useTranslation('call');

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent maxW="450px" py={6} borderRadius={'24px'}>
				<ModalBody p={0}>
					<VStack spacing={4} align="center">
						<Image src="/icons/mic-icon.svg" alt="Jam Logo" w="48px" h="48px" />
						<Text mx={6} fontSize="xl" fontWeight="bold">
							{t('mic_permissions_modal_title')}
						</Text>
						<Text mx={6} textAlign="center" color="gray.600">
							{t('mic_permissions_modal_text')}
						</Text>
						<Divider />
						<Box width={'full'} px={6}>
							<Button
								px={6}
								w="full"
								colorScheme="brand"
								bg="brand.500"
								onClick={onClose}
							>
								{t('understood')}
							</Button>
						</Box>
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default MicPermissionModal;
