import { Salutation } from '../features/home/components/Salutation';
import { NextStory } from '../features/home/components/NextStory';
import { useAuthInfo } from '@propelauth/react';
import { AppLayout } from '../common/components/AppLayout';
import { GetInspired } from '../features/home/components/GetInspired';
import { LearnFromRealCalls } from '../features/home/components/LearnFromRealCalls';
import { KeepLearning } from '../features/home/components/KeepLearning';
import { Flex } from '@chakra-ui/react';
import { CurrentTrack } from '../features/home/components/CurrentTrack';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../redux/hooks';
import { selectLanguage } from '../redux/slice';
import { OnboardingGoals } from '../features/home/components/OnboardingGoals';
import { OnboardingSteps } from '../common/components/onboarding/OnboardinSteps';
import { OnboardingNavigation } from '../common/components/onboarding/OnboardingNavigation';
import { TourProvider } from '@reactour/tour';
import { OnboardingModal } from '../common/components/onboarding/OnboardingModal';
import { OnboardingFeature } from '../common/types/Onboarding';

export const HomePage = () => {
	const { user: authUser } = useAuthInfo();
	const { t } = useTranslation('home');
	const language = useAppSelector(selectLanguage);
	const day = new Date().toLocaleDateString(language, { weekday: 'long' });

	return (
		<TourProvider
			steps={OnboardingSteps}
			components={{
				Navigation: () => (
					<OnboardingNavigation feature={OnboardingFeature.HOME_PAGE} />
				),
			}}
		>
			<AppLayout>
				<Salutation
					text={t('salutation', { day, name: authUser?.firstName || '' })}
				/>
				<Flex direction={'column'} gap="10">
					<OnboardingModal />
					<Flex ml="auto" gap="4">
						<OnboardingGoals />
						<CurrentTrack />
					</Flex>
					<NextStory page="home" />
					<KeepLearning />
					<LearnFromRealCalls />
					<GetInspired />
				</Flex>
			</AppLayout>
		</TourProvider>
	);
};
