import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Text,
	Textarea,
	VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
	useReportIssueMutation,
	RequestTypeId,
} from '../../../redux/api/reportApi';
import { useAuthInfo } from '@propelauth/react';

interface RequestEditsModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const RequestEditsModal = ({
	isOpen,
	onClose,
}: RequestEditsModalProps) => {
	const { t } = useTranslation('common');
	const { user } = useAuthInfo();
	const [reportIssue] = useReportIssueMutation();

	const [request, setRequest] = useState('');

	const handleSubmit = async () => {
		if (!user) return;
		const currentUrl = window.location.href;

		const summary = `${user.firstName ?? ''} ${user.lastName ?? ''} requested edits`;
		const description = ` 
		\n ${request} 
		\n User email: ${user.email} 
		\n User first name: ${user.firstName ?? ''} 
		\n User last name: ${user.lastName ?? ''}   
		\n Current URL: ${currentUrl}`;

		await reportIssue({
			summary,
			description,
			requestTypeId: RequestTypeId.CONTENT_EDIT,
		});
		onClose();
		setRequest('');
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{t('whatWouldYouLikeToChange')}</ModalHeader>
				<ModalBody>
					<VStack spacing={4} align="stretch">
						<Textarea
							value={request}
							onChange={(e) => setRequest(e.target.value)}
							placeholder={t('enterYourRequestHere') ?? ''}
							minH="100px"
							resize="vertical"
						/>
						<Text fontSize="sm" color="gray.500">
							{t('requestsAreTypicallyImplementedWithin3WorkingDays')}
						</Text>
					</VStack>
				</ModalBody>
				<ModalFooter gap={2}>
					<Button variant="ghost" onClick={onClose}>
						{t('cancel')}
					</Button>
					<Button
						bg="#4241E4"
						color="white"
						_hover={{ bg: '#3736B5' }}
						onClick={() => {
							void handleSubmit();
						}}
						isDisabled={!request.trim()}
					>
						{t('submitRequest')}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
