import { Language } from '@jam/api-sdk';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

type ApplicationState = {
	language: 'en' | 'de' | null;
	accessToken: string;
	menuIsOpen: boolean;
	currentCallStartTime: number | null;
	hasUserMadeCall: boolean;
	reportIssueModalIsOpen: boolean;
};

const initialState: ApplicationState = {
	language: null,
	accessToken: '',
	currentCallStartTime: null,
	hasUserMadeCall: false,
	menuIsOpen: true,
	reportIssueModalIsOpen: false,
};

export const applicationStateSlice = createSlice({
	name: 'applicationState',
	initialState,
	reducers: {
		setLanguage: (state: ApplicationState, action: PayloadAction<Language>) => {
			state.language = action.payload;
		},
		toggleMenu: (state: ApplicationState) => {
			state.menuIsOpen = !state.menuIsOpen;
		},
		setHasUserMadeCall: (
			state: ApplicationState,
			action: PayloadAction<boolean>
		) => {
			state.hasUserMadeCall = action.payload;
		},
		setCurrentCallStartTime: (
			state: ApplicationState,
			action: PayloadAction<number | null>
		) => {
			state.currentCallStartTime = action.payload;
		},
		setAccessToken: (
			state: ApplicationState,
			action: PayloadAction<string>
		) => {
			state.accessToken = action.payload;
		},
		setReportIssueModalIsOpen: (
			state: ApplicationState,
			action: PayloadAction<boolean>
		) => {
			state.reportIssueModalIsOpen = action.payload;
		},
	},
});

export const {
	setLanguage,
	setAccessToken,
	setCurrentCallStartTime,
	setHasUserMadeCall,
	toggleMenu,
	setReportIssueModalIsOpen,
} = applicationStateSlice.actions;

export const selectLanguage = (state: RootState) =>
	state.applicationState.language ?? 'de';

export const selectHasUserMadeCall = (state: RootState) =>
	state.applicationState.hasUserMadeCall;

export const selectCurrentCallStartTime = (state: RootState) =>
	state.applicationState.currentCallStartTime;

export const selectMenuIsOpen = (state: RootState) =>
	state.applicationState.menuIsOpen;

export const selectReportIssueModalIsOpen = (state: RootState) =>
	state.applicationState.reportIssueModalIsOpen;
