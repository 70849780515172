import {
	SchemaCreateLearnerStoryItemFeedbackDto,
	SchemaFirstStepsGoalsResponseDto,
	SchemaJourneyContentAllocationResponseDto,
	SchemaJourneyContentAllocationStatusResponseDto,
	SchemaLearnerStoryFeedbackResponseDto,
	SchemaLearnerStoryItemFeedbackResponseDto,
	SchemaNextRefresherResponseDto,
	SchemaSessionResponseDto,
	SessionResponseDTOAnalysisState,
} from '@jam/api-sdk';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

export type JourneyContentAllocationDetails =
	SchemaJourneyContentAllocationResponseDto &
		SchemaJourneyContentAllocationStatusResponseDto;

const api = createApi({
	reducerPath: 'learner-api',
	tagTypes: [
		'user-feedback',
		'refreshers',
		'story-sessions',
		'user-item-feedback',
	],
	baseQuery: fetchBaseQuery({
		baseUrl: `${
			process.env
				.REACT_APP_SALES_ENABLEMENT_PLATFORM_NEST_API_BASE_URL as string
		}/api/v1`,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).applicationState.accessToken;

			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}

			return headers;
		},
	}),
	endpoints: (builder) => ({
		getFirstStepsGoals: builder.query<SchemaFirstStepsGoalsResponseDto, void>({
			query: () => {
				return {
					url: 'goals/first-steps',
					method: 'GET',
				};
			},
		}),
		getJourneyContentAllocationList: builder.query<
			SchemaJourneyContentAllocationResponseDto[],
			void
		>({
			query: () => 'content-allocation/journey',
		}),
		getUserJourneyContentAllocation: builder.query<
			JourneyContentAllocationDetails | null,
			void
		>({
			async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
				// get a random user
				const journeyResult = await fetchWithBQ('content-allocation/journey');
				if (journeyResult.error) {
					return { error: journeyResult.error };
				}
				const journeys =
					journeyResult.data as SchemaJourneyContentAllocationResponseDto[];

				if (journeys.length === 0) {
					return { data: null };
				}
				const journey = journeys[0];

				const statusResult = await fetchWithBQ(
					`content-allocation/journey/state/${journey.id}`
				);

				if (statusResult.error) {
					return { error: statusResult.error };
				}
				const status =
					statusResult.data as SchemaJourneyContentAllocationStatusResponseDto;
				return { data: { ...journey, ...status } };
			},
		}),

		getStoryLastSession: builder.query<SchemaSessionResponseDto | null, string>(
			{
				query: (id: string) => `sessions/story/${id}`,
				transformResponse: (response: SchemaSessionResponseDto[]) => {
					// sort by created date desc
					const sorted = response.sort((a, b) => {
						return (
							new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
						);
					});
					return sorted.length > 0 ? sorted[0] : null;
				},
			}
		),

		getStorySessions: builder.query<SchemaSessionResponseDto[], string>({
			providesTags: ['story-sessions'],
			query: (id: string) => `sessions/story/${id}`,
		}),
		getRefreshers: builder.query<
			SchemaNextRefresherResponseDto[],
			number | undefined
		>({
			providesTags: ['refreshers'],
			query: (limit = 3) => `content-allocation/refresher?limit=${limit}`,
		}),
		getNextMission: builder.query<
			{ contentAllocationId: string; storyReferenceId: string },
			void
		>({
			providesTags: ['refreshers'],
			query: () => 'content-allocation/next-mission',
		}),
		skipRefresher: builder.mutation<void, string>({
			invalidatesTags: ['refreshers'],
			query: (id: string) => ({
				url: `content-allocation/refresher/skip/${id}`,
				method: 'PATCH',
			}),
		}),
		getSessionDetails: builder.query<SchemaSessionResponseDto, string>({
			query: (id: string) => `sessions/${id}`,
		}),
		getUserTotalExperience: builder.query<number, void>({
			query: () => `experience/total`,
		}),
		getUserItemFeedbackBySession: builder.query<
			SchemaLearnerStoryItemFeedbackResponseDto[],
			string
		>({
			providesTags: ['user-item-feedback'],
			query: (sessionId: string) =>
				`user-feedback/learner/story/item?sessionId=${sessionId}`,
		}),

		getUserStoryFeedback: builder.query<
			SchemaLearnerStoryFeedbackResponseDto[],
			string
		>({
			providesTags: ['user-feedback'],
			query: (sessionId: string) =>
				`user-feedback/learner/story?storyReferenceId=${sessionId}`,
		}),

		addUserItemFeedback: builder.mutation<
			SchemaCreateLearnerStoryItemFeedbackDto,
			{
				content?: string;
				sessionId: string;
				storyItemReferenceId: string;
				positive: boolean;
				preset?: string[];
			}
		>({
			invalidatesTags: ['user-item-feedback'],
			query: (req) => ({
				url: `user-feedback/learner/story/item`,
				method: 'POST',
				body: req,
			}),
		}),

		getAllSessions: builder.query<SchemaSessionResponseDto[], void>({
			query: () => `sessions`,
			transformResponse: (response: SchemaSessionResponseDto[]) => {
				// sort by created date desc

				return response
					.filter(
						(session) =>
							session.analysis !== null &&
							session.analysis.state === SessionResponseDTOAnalysisState.DONE
					)
					.sort((a, b) => {
						return (
							new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
						);
					});
			},
		}),
		getAllSessionsByUserId: builder.query<SchemaSessionResponseDto[], string>({
			query: (userId: string) => `sessions/user/${userId}`,
			transformResponse: (response: SchemaSessionResponseDto[]) => {
				// sort by created date desc
				return response
					.filter(
						(session) =>
							session.analysis !== null &&
							session.analysis.state === SessionResponseDTOAnalysisState.DONE
					)
					.sort((a, b) => {
						return (
							new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
						);
					});
			},
		}),
		createCall: builder.mutation<
			{ id: string; call: { assistantReferenceId: string } },
			{
				storyId: string;
				contentAllocationId: string | null | undefined;
			}
		>({
			query: (req) => ({
				url: 'sessions',
				method: 'POST',
				body: req,
			}),
		}),
		deleteSession: builder.mutation<void, string>({
			query: (id: string) => ({
				url: `sessions/${id}`,
				method: 'DELETE',
			}),
		}),
		addUserFeedback: builder.mutation<
			{ id: string },
			{
				storyReferenceId: string;
				rating: number;
				content: string;
			}
		>({
			invalidatesTags: ['user-feedback'],
			query: (req) => ({
				url: `user-feedback/learner/story`,
				method: 'POST',
				body: req,
			}),
		}),
	}),
});

export const {
	useGetUserItemFeedbackBySessionQuery,
	useAddUserItemFeedbackMutation,
	useGetStorySessionsQuery,
	useLazyGetStorySessionsQuery,
	useGetNextMissionQuery,
	useGetFirstStepsGoalsQuery,
	useGetJourneyContentAllocationListQuery,
	useSkipRefresherMutation,
	useGetRefreshersQuery,
	useGetUserJourneyContentAllocationQuery,
	useCreateCallMutation,
	useGetAllSessionsQuery,
	useGetAllSessionsByUserIdQuery,
	useGetSessionDetailsQuery,
	useDeleteSessionMutation,
	useAddUserFeedbackMutation,
	useGetStoryLastSessionQuery,
	useLazyGetStoryLastSessionQuery,
	useGetUserTotalExperienceQuery,
	useLazyGetUserTotalExperienceQuery,
	useGetUserStoryFeedbackQuery,
} = api;
export default api;
