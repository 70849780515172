import {
	Box,
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	ListItem,
	Stack,
	Text,
	UnorderedList,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { ZodType, z } from 'zod';
import { useChangePasswordMutation } from '../../../redux/api/accountApi';
import { useTranslation } from 'react-i18next';
import { useLogoutFunction } from '@propelauth/react';

type NewPasswordForm = {
	password: string;
	confirmPassword: string;
};

const NewPasswordSchema: ZodType<NewPasswordForm> = z
	.object({
		password: z
			.string()
			.min(8, 'Password must be at least 8 characters long')
			.regex(/[a-z]/, 'Password must contain at least one lowercase letter')
			.regex(/[A-Z]/, 'Password must contain at least one uppercase letter')
			.regex(/[0-9]/, 'Password must contain at least one number'),
		confirmPassword: z.string(),
	})
	.refine((data) => data.password === data.confirmPassword, {
		message: "Passwords don't match",
		path: ['confirmPassword'], // path of error
	});

export const PasswordChangeForm = () => {
	const logout = useLogoutFunction();
	const { t } = useTranslation('settings');
	const [changePassword] = useChangePasswordMutation();
	const {
		register,
		handleSubmit,
		setValue,
		formState: { isDirty, errors, isSubmitting },
	} = useForm<NewPasswordForm>({
		resolver: zodResolver(NewPasswordSchema),
	});

	const onReset = () => {
		setValue('password', '');
		setValue('confirmPassword', '');
	};

	const onSubmit = async (data: NewPasswordForm) => {
		await changePassword({
			password: data.password,
		});
		await logout(true);
	};

	return (
		<Flex direction={'column'}>
			<Text fontWeight={'medium'} fontSize={'24px'} color={'#757575'}>
				{t('change-password')}
			</Text>
			<Stack mt="8" spacing={4} maxW={'500px'}>
				<FormControl isInvalid={!!errors.password}>
					<FormLabel>{t('new-password')}</FormLabel>
					<Input
						type={'password'}
						borderRadius={'full'}
						{...register('password')}
					/>
					{errors.password?.message && (
						<FormErrorMessage>{errors.password.message}</FormErrorMessage>
					)}
				</FormControl>
				<FormControl isInvalid={!!errors.confirmPassword}>
					<FormLabel>{t('confirm-password')}</FormLabel>
					<Input
						type={'password'}
						borderRadius={'full'}
						{...register('confirmPassword')}
					/>
					{errors.confirmPassword?.message && (
						<FormErrorMessage>
							{errors.confirmPassword.message}
						</FormErrorMessage>
					)}
				</FormControl>
				<Box mt="2">
					<Text my="2" fontSize={'12px'} color={'#757575'}>
						{t('password-conditions.title')}
					</Text>
					<UnorderedList fontSize={'12px'} color="#757575">
						<ListItem>{t('password-conditions.uppercase')}</ListItem>
						<ListItem>{t('password-conditions.lowercase')}</ListItem>
						<ListItem>{t('password-conditions.number')}</ListItem>
						<ListItem>{t('password-conditions.length')}</ListItem>
					</UnorderedList>
					<Text mt="2" fontSize={'12px'} color={'#757575'} fontStyle={'italic'}>
						{t('password-conditions.note')}
					</Text>
				</Box>
				<Flex mt="4" gap="5">
					<Button
						id="change-password-button"
						borderRadius={'full'}
						type="submit"
						isLoading={isSubmitting}
						px="6"
						bgColor={isDirty ? 'brand.500' : 'gray.300'}
						size="md"
						onClick={() => {
							if (!isDirty) return;
							void handleSubmit(onSubmit)();
						}}
					>
						{t('save')}
					</Button>
					<Button
						type="reset"
						color={'black'}
						bg="transparent"
						colorScheme="red"
						size="md"
						onClick={() => {
							onReset();
						}}
					>
						{t('cancel')}
					</Button>
				</Flex>
			</Stack>
		</Flex>
	);
};
