import {
	Avatar,
	Box,
	Divider,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Stack,
	Text,
} from '@chakra-ui/react';
import { SchemaCompletedUserActivityReposeDto } from '@jam/api-sdk';
import { ArrowDown, ArrowUp, DotsThreeVertical } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Chip } from '../../../../../common/components/Chip';

type UserActivityListBaseType = 'most' | 'least';

type ActiveUsersListProps = {
	type: UserActivityListBaseType;
	completedSessionUserActivities: SchemaCompletedUserActivityReposeDto['mostActive'];
};

export const ActiveUsersList = ({
	type,
	completedSessionUserActivities,
}: ActiveUsersListProps) => {
	const { t } = useTranslation(['dashboard', 'common']);
	const navigate = useNavigate();
	const text = type === 'most' ? t('most_rounds') : t('least_rounds');
	const color = type === 'most' ? '#147129' : '#FF0000';
	const bgColor = type === 'most' ? '#EAFDEE' : '#FDE8E8';
	const icon =
		type === 'most' ? <ArrowUp size={16} /> : <ArrowDown size={16} />;
	return (
		<Box
			border={'1px'}
			borderColor="#D9D9D9"
			borderRadius={'16px'}
			w="100%"
			p="6"
		>
			<Stack spacing={'3'} divider={<Divider />}>
				<Flex alignItems="center" w="100%" pr="3">
					<Box flexShrink={1} flex="0.6">
						<Chip
							px="8px"
							border={0}
							py="4px"
							bgColor={bgColor}
							color={color}
							text={text}
							icon={icon}
						/>
					</Box>
					<Text
						textAlign={'center'}
						flexShrink={0}
						flex="0.2"
						fontSize={'12px'}
						color={'#757575'}
					>
						{t('common:last_week')}
					</Text>
					<Text
						textAlign={'center'}
						flexShrink={0}
						flex="0.2"
						fontSize={'12px'}
						color={'#757575'}
					>
						{t('dashboard:total_rounds')}
					</Text>
				</Flex>
				{completedSessionUserActivities.map((completedSessionUserActivity) => {
					return (
						<Flex
							onClick={() => {
								navigate(
									`/dashboard/user/${completedSessionUserActivity.userId}`
								);
							}}
							cursor={'pointer'}
							alignItems="center"
							w="100%"
							key={completedSessionUserActivity.userId}
						>
							<Flex alignItems={'center'} flexShrink={1} flex="0.6" gap="3">
								<Avatar
									size="sm"
									name={completedSessionUserActivity.userName ?? 'N/A'}
									src={completedSessionUserActivity.userPictureUrl}
									bg="gray"
								/>
								<Box>
									<Text fontSize="16px" fontWeight="medium">
										{completedSessionUserActivity.userName ?? 'Unknown User'}
									</Text>
									<Text fontSize="14px" color="#757575">
										{completedSessionUserActivity.organizationName}
									</Text>
								</Box>
							</Flex>
							<Text
								textAlign={'center'}
								flexShrink={0}
								flex="0.2"
								fontSize={'30px'}
							>
								{completedSessionUserActivity.sessionCompletedLastWeek}
							</Text>
							<Flex justifyContent={'center'} flexShrink={0} flex="0.2">
								<Box
									w="fit-content"
									bg="#F5F5F5"
									px="2"
									py="1"
									borderRadius={'20px'}
								>
									<Text>
										{completedSessionUserActivity.sessionsCompletedTotal}
									</Text>
								</Box>
							</Flex>
							<Menu>
								<MenuButton>
									<DotsThreeVertical size={15} />
								</MenuButton>
								<MenuList>
									<MenuItem onClick={() => {}}>
										<Text>{t('common:see_details')}</Text>
									</MenuItem>
								</MenuList>
							</Menu>
						</Flex>
					);
				})}
			</Stack>
		</Box>
	);
};
