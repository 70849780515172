import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Box,
	Link as ChakraLink,
	Divider,
	Flex,
	Text,
} from '@chakra-ui/react';

import { SchemaJourneyResponseDto } from '@jam/api-sdk';
import { CaretDown, CaretUp, Clock } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Chip } from '../../../common/components/Chip';
import { MissionLineItem } from './MissionLineItem';

type TrackCardProps = {
	track: SchemaJourneyResponseDto['tracks'][0];
};

export const TrackCard = ({ track }: TrackCardProps) => {
	const { t } = useTranslation('common');
	return (
		<Flex
			p={6}
			borderWidth={'1.5px'}
			borderRadius={'24px'}
			borderColor={'#D9D9D9'}
			direction={'column'}
		>
			<ChakraLink
				fontSize={'20px'}
				fontWeight={'500'}
				color={'#0B1215'}
				as={ReactRouterLink}
				to={`/track/${track.id}`}
				variant={''}
				_hover={{ textDecoration: 'underline' }}
			>
				{track.title}
			</ChakraLink>
			{track.conversation_topic.length > 0 && (
				<Flex mt={3} gap={2}>
					{track.conversation_topic.map((topic) => (
						<Chip
							key={topic}
							bg={'#FAFAFA'}
							fontSize={'13px'}
							color={'#757575'}
							text={topic}
						/>
					))}
				</Flex>
			)}
			<Divider my="2" />
			<Accordion allowMultiple>
				<AccordionItem border={0}>
					{({ isExpanded }) => (
						<Box>
							<AccordionButton
								px={0}
								mx={0}
								_hover={{ background: 'transparent' }}
								w="100%"
							>
								<Flex justify={'space-between'} w="100%" gap={2}>
									<Flex alignItems={'center'} w="100%" gap={2}>
										{isExpanded ? (
											<CaretUp size={20} weight="bold" />
										) : (
											<CaretDown size={20} weight="bold" />
										)}
										<Text fontSize="16px" fontWeight="500">
											{track.stories.length} {t('missions')}
										</Text>
									</Flex>
									<Chip
										mt={5}
										gap={1}
										bg={'transparent'}
										border={0}
										color={'#757575'}
										icon={<Clock size={16} />}
										text={`${track.duration} ${t('mins')}`}
									/>
								</Flex>
							</AccordionButton>
							<AccordionPanel px={0} mx={0}>
								<Flex direction={'column'}>
									{track.stories.map((mission, index) => (
										<MissionLineItem
											trackId={track.id}
											key={mission.title}
											mission={mission}
											index={index}
										/>
									))}
								</Flex>
							</AccordionPanel>
						</Box>
					)}
				</AccordionItem>
			</Accordion>
		</Flex>
	);
};
