import {
	Box,
	Flex,
	IconButton,
	Input,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Select,
	SkeletonText,
	Stack,
	Text,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { SchemaUserResponseDto, WhoAmIResponseRole } from '@jam/api-sdk';
import { DotsThreeVertical } from '@phosphor-icons/react';
import { useAuthInfo } from '@propelauth/react';
import { createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip } from '../../../common/components/Chip';
import CustomTable from '../../../common/components/custom-table/CustomTable';
import { useUserOrg } from '../../../common/hooks/useUserOrg';
import {
	useChangeUserRoleMutation,
	useEnableUserMutation,
	useGetOrganizationUsersQuery,
} from '../../../redux/api/accountApi';
import RemoveUserDialog from './RemoveUserDialog';

const LoadingState = () => {
	return (
		<Flex mt="5" direction={'column'} gap="6">
			<Box maxW="300px">
				<SkeletonText noOfLines={1} height={'30px'} />
			</Box>
			<SkeletonText w={'full'} noOfLines={5} spacing={'5'} />
		</Flex>
	);
};

export const UsersTable = () => {
	const { user } = useAuthInfo();
	const { data, isLoading } = useGetOrganizationUsersQuery();
	const { t } = useTranslation(['settings', 'common']);
	const toast = useToast();
	const [activeUser, setActiveUser] = useState<string | null>(null);
	const [changeRole] = useChangeUserRoleMutation();
	const [enableUser] = useEnableUserMutation();
	const org = useUserOrg();

	const [search, setSearch] = useState('');
	const {
		isOpen: deleteUserDialogIsOpen,
		onOpen: deleteUserDialogOnOpen,
		onClose: deleteUserDialogOnClose,
	} = useDisclosure();

	if (!org) return null;

	const onRoleChange = async (userId: string, role: WhoAmIResponseRole) => {
		await changeRole({ userId, orgId: org.orgId, role });
		toast({
			title: t('role-changed-successfully'),
			status: 'success',
			duration: 3000,
			isClosable: true,
		});
	};

	const columnHelper = createColumnHelper<SchemaUserResponseDto>();
	const columns = [
		columnHelper.accessor('email', {
			header: t('common:email').toString(),
			cell: ({ row }) => (
				<Text fontWeight={'medium'}>{row.original.email}</Text>
			),
		}),
		columnHelper.accessor('role', {
			header: t('common:role').toString(),
			cell: ({ row }) => {
				return (
					<Select
						borderRadius={'10px'}
						borderColor={'black'}
						size={'sm'}
						disabled={user?.userId === row.original.id}
						maxW="100px"
						value={row.original.role}
						onChange={(e) => {
							void onRoleChange(
								row.original.id,
								e.target.value as WhoAmIResponseRole
							);
						}}
					>
						<option value="Member">Member</option>{' '}
						<option value="Manager">Manager</option>
						<option value="Owner">Owner</option>
					</Select>
				);
			},
		}),
		columnHelper.display({
			header: t('common:status').toString(),
			cell: ({ row }) => (
				<Chip
					borderColor={row.original.enabled ? 'black' : 'gray.400'}
					textColor={row.original.enabled ? 'black' : 'gray.400'}
					py="2px"
					px="8px"
					bg={row.original.enabled ? '#EBF934' : 'gray.50'}
					text={row.original.enabled ? 'Active' : 'Disabled'}
				/>
			),
		}),
		columnHelper.display({
			header: 'Actions',
			meta: { hideTitle: true, name: 'Actions' },
			cell: ({ row }) => {
				if (user?.userId === row.original.id) {
					return <></>;
				}
				return (
					<Menu>
						<MenuButton
							as={IconButton}
							aria-label="Options"
							icon={<DotsThreeVertical />}
							variant="unstyled"
						/>
						<MenuList>
							{row.original.enabled && (
								<MenuItem
									isDisabled={user?.userId === row.original.id}
									onClick={() => {
										setActiveUser(row.original.id);
										deleteUserDialogOnOpen();
									}}
								>
									{t('disable-user')}
								</MenuItem>
							)}
							{!row.original.enabled && (
								<MenuItem
									isDisabled={user?.userId === row.original.id}
									onClick={() => {
										void enableUser({ userId: row.original.id });
										toast({
											title: t('user-enabled-successfully'),
											status: 'success',
											duration: 3000,
											isClosable: true,
										});
									}}
								>
									{t('enable-user')}
								</MenuItem>
							)}
						</MenuList>
					</Menu>
				);
			},
		}),
	];
	const customFilter = [{ id: 'email', value: search }];

	const userData = data ?? [];

	if (isLoading) {
		return <LoadingState />;
	}

	return (
		<Stack direction="column" spacing="5">
			<Text fontSize="2xl" fontWeight="semibold">
				{t('users')}
			</Text>
			<Flex gap="5">
				<Input
					maxW={'500'}
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					borderRadius={'full'}
					placeholder={t('search-by-email') ?? ''}
				/>
				<RemoveUserDialog
					userId={activeUser}
					isOpen={deleteUserDialogIsOpen}
					onClose={() => {
						deleteUserDialogOnClose();
						setActiveUser(null);
					}}
				/>
			</Flex>
			<CustomTable<SchemaUserResponseDto>
				data={userData}
				columns={columns}
				customFilters={customFilter}
			/>
		</Stack>
	);
};
