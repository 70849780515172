import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

export enum RequestTypeId {
	BUG_REPORT = '6',
	CONTENT_EDIT = '9',
}

const SERVICE_DESK_ID = '1';

const api = createApi({
	reducerPath: 'report-api',
	baseQuery: fetchBaseQuery({
		baseUrl: `${
			process.env
				.REACT_APP_SALES_ENABLEMENT_PLATFORM_NEST_API_BASE_URL as string
		}/api/v1`,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).applicationState.accessToken;

			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}

			return headers;
		},
	}),
	endpoints: (builder) => ({
		reportIssue: builder.mutation<
			void,
			{
				summary: string;
				description: string;
				requestTypeId: string;
			}
		>({
			query: ({ summary, description, requestTypeId }) => ({
				url: 'issue-report',
				method: 'POST',
				body: {
					serviceDeskId: SERVICE_DESK_ID,
					requestTypeId,
					requestFieldValues: {
						summary,
						description,
					},
				},
			}),
		}),
	}),
});

export const { useReportIssueMutation } = api;
export default api;
