import { Stack } from '@chakra-ui/react';
import { useUserOrg } from '../../../../../common/hooks/useUserOrg';
import { PendingInvites } from '../../../../settings/components/PendingInvites';

export const UserOrgPendingInvites = () => {
	const org = useUserOrg();

	if (!org) return null;
	return (
		<Stack direction="column" spacing="5" py="10">
			<PendingInvites orgId={org.orgId} />
		</Stack>
	);
};
