import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Box,
	Divider,
	Flex,
	Popover,
	PopoverContent,
	PopoverTrigger,
	Stack,
	Text,
} from '@chakra-ui/react';
import { Circle, Info, Minus, Plus } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { Chip } from '../../../../../../common/components/Chip';
import { SolvedStatusToColorMap } from '../../../../../user-history/components/HistoryTable';
import { MissionWithSession } from '../../../../hooks/useGetUserJourneyWithSessions';

type MissionItemProps = {
	mission: MissionWithSession;
};

export const MissionItem = ({ mission }: MissionItemProps) => {
	const { t } = useTranslation('dashboard');
	return (
		<Box border={'1px'} borderColor={'#D9D9D9'} borderRadius={'24px'} p="6">
			<Flex gap="2" alignItems={'center'}>
				<Text fontSize={'16px'} fontWeight={'500'}>
					{mission.title}
				</Text>
				<Popover placement="right">
					<PopoverTrigger>
						<Info color="#D9D9D9" size={20} />
					</PopoverTrigger>
					<PopoverContent px="10" py="5" w="fit-content">
						<Flex direction={'column'} gap="2">
							<Text fontSize={'16px'} fontWeight={'500'}>
								{mission.title}
							</Text>
							<Box>
								<Text fontSize={'14px'} fontWeight={'500'}>
									Scenario
								</Text>
								<Flex direction={'column'}>
									{mission.situation.description
										.trim()
										.split('.')
										.filter((x) => !!x)
										.map((sentence) => (
											<Flex alignItems={'center'} gap="1" key={sentence}>
												<Box flexShrink={0}>
													<Circle size="5" weight="fill" />
												</Box>
												<Text fontSize="14px">{sentence}</Text>
											</Flex>
										))}
								</Flex>
							</Box>
							<Box>
								<Text fontSize={'14px'} fontWeight={'500'}>
									Persona
								</Text>
								<Text fontSize={'14px'}>{mission.persona.name}</Text>
							</Box>
						</Flex>
					</PopoverContent>
				</Popover>
			</Flex>
			<Divider mt="6" />
			<Accordion allowMultiple mt="6">
				<AccordionItem border={0}>
					{({ isExpanded }) => (
						<Box>
							<AccordionButton _hover={{ background: 'transparent' }} w="100%">
								<Flex
									alignItems={'center'}
									w="100%"
									justifyContent={'space-between'}
								>
									<Stack
										alignItems={'center'}
										direction={'row'}
										spacing={4}
										divider={<Divider orientation="vertical" height={'30px'} />}
									>
										<Flex direction={'column'} gap={2}>
											<Text fontSize={'12px'} color="#757575">
												{t('detailed_user_view.number_of_sessions')}
											</Text>
											<Chip
												px={3}
												py={1}
												bgColor={'#F5F5F5'}
												border={0}
												color="#757575"
												text={mission.sessionsCount.toString()}
											/>
										</Flex>
										{mission.sessionsCount > 0 && (
											<Flex direction={'column'} gap={2}>
												<Text fontSize={'12px'} color="#757575">
													{t('detailed_user_view.average_score')}
												</Text>
												<Chip
													px={3}
													py={1}
													bgColor={'#F5F5F5'}
													border={0}
													color="#757575"
													text={mission.averageSessionScore.toString()}
												/>
											</Flex>
										)}
										{mission.sessionsCount > 0 && (
											<Flex direction={'column'} gap={2}>
												<Text fontSize={'12px'} color="#757575">
													{t('detailed_user_view.score_per_rounds_played')}
												</Text>
												<Flex direction={'row'} gap={2}>
													{mission.sessionScores.map((score, index) => (
														<Chip
															key={`${score}-${index}`}
															px={3}
															py={1}
															bgColor={'#F5F5F5'}
															border={0}
															color="#757575"
															text={score.toString()}
														/>
													))}
												</Flex>
											</Flex>
										)}
									</Stack>
									{isExpanded ? <Minus size={20} /> : <Plus size={20} />}
								</Flex>
							</AccordionButton>
							<AccordionPanel>
								<Divider color={'#757575'} mt="2" mb="4" />
								<Stack
									direction={'row'}
									spacing={4}
									divider={<Divider orientation="vertical" height={'100'} />}
								>
									<Flex direction={'column'} gap={2}>
										<Text fontSize={'14px'} color="#757575">
											Task: {mission.tasks.length}
										</Text>
										{mission.tasks.map((task, index) => (
											<Chip
												key={index}
												px={3}
												borderRadius={'8px'}
												bgColor={'#FAFAFA'}
												borderColor={'#D9D9D9'}
												fontWeight={'500'}
												color="#757575"
												text={task.title}
											/>
										))}
									</Flex>
									<Flex direction={'column'} gap={2}>
										<Text fontSize={'14px'} color="#757575">
											Results per round:
										</Text>
										{mission.tasks.map((task) => (
											<Flex key={task.title} mx="2" alignItems={'center'}>
												{task.items.map((result, index) => {
													return (
														<Box
															ml="-2"
															w="18px"
															h="18px"
															bg={SolvedStatusToColorMap[result]}
															borderRadius={'full'}
															borderWidth={2}
															borderColor={'white'}
															key={index}
														/>
													);
												})}
											</Flex>
										))}
									</Flex>
								</Stack>
							</AccordionPanel>
						</Box>
					)}
				</AccordionItem>
			</Accordion>
		</Box>
	);
};
