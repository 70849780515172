import { useParams } from 'react-router-dom';
import { AppLayout } from '../../common/components/AppLayout';
import { BreadCrumbs } from '../../features/manager-tracks/components/BreadCrumbs';
import { MissionDetail } from '../../features/manager-tracks/components/MissionDetail';
import {
	useGetJourneyTrack,
	useGetMission,
} from '../../features/manager-tracks/hooks/useGetCurrentJourney';

export const ManagerMissionDetailPage = () => {
	const { trackId, missionId } = useParams();
	const track = useGetJourneyTrack(trackId);
	const mission = useGetMission(trackId, missionId);

	if (!track || !mission) {
		return null;
	}
	return (
		<AppLayout>
			<BreadCrumbs
				backLink={`/track/${track.id}`}
				items={[
					{
						title: track.title,
						link: `/track/${track.id}`,
					},
					{
						title: mission.title,
						link: `/track/${track.id}/mission/${mission.id}`,
					},
				]}
			/>
			<MissionDetail />
		</AppLayout>
	);
};
