import { SchemaJourneyResponseDto } from '@jam/api-sdk';
import { useGetJourneyDetailsQuery } from '../../redux/api/cmsApi';
import {
	JourneyContentAllocationDetails,
	useGetUserJourneyContentAllocationQuery,
} from '../../redux/api/learnerApi';

const getFirstNotCompletedStory = (
	userJourney: JourneyContentAllocationDetails,
	journeyDetails: SchemaJourneyResponseDto
) => {
	const { tracks } = journeyDetails;
	const completedStories = userJourney.trackStatuses.flatMap(
		(trackStatus) => trackStatus.completedStoryIds
	);

	for (const [index, track] of tracks.entries()) {
		for (const story of track.stories) {
			if (!completedStories.includes(story.id)) {
				return {
					contentAllocationId: userJourney.id,
					story,
					journeyId: userJourney.journeyReferenceId,
					track,
					trackIndex: index,
				};
			}
		}
	}
	return null;
};

export const useGetFirstNotCompletedStory = () => {
	const { data: userJourney } = useGetUserJourneyContentAllocationQuery();
	const { data: journeyDetails } = useGetJourneyDetailsQuery(
		userJourney?.journeyReferenceId ?? '',
		{
			skip: !userJourney,
		}
	);

	if (!journeyDetails || !userJourney) {
		return undefined;
	}
	return getFirstNotCompletedStory(userJourney, journeyDetails);
};
