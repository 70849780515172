import {
	Tab,
	TabIndicator,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
} from '@chakra-ui/react';
import { AppLayout } from '../common/components/AppLayout';
import { useTranslation } from 'react-i18next';
import { Salutation } from '../features/home/components/Salutation';
import { useAuthInfo } from '@propelauth/react';
import { useActiveTabIndex } from '../features/settings/hooks/useActiveTabIndex';
import { PersonalSettingsTab } from '../features/settings/components/PersonalSettingsTab';
import { CompanySettingsTab } from '../features/settings/components/CompanySettingsTab';
import { useIsManagerOrOwnerAtOrg } from '../common/hooks/useIsManager';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../redux/slice';

export const SettingsPage = () => {
	const { t } = useTranslation('settings');
	const language = useSelector(selectLanguage);
	const managedOrgId = useIsManagerOrOwnerAtOrg();
	const { activeTabIndex, setActiveTabIndex } = useActiveTabIndex();
	const { user } = useAuthInfo();
	const helperText =
		activeTabIndex == 1 ? t('team_settings_helper') : t('account-description');
	if (!user) return null;
	return (
		<AppLayout>
			<Salutation text={t('settings')} />
			<Text
				fontSize={'24px'}
				fontWeight={'500'}
			>{`${user.firstName ?? ''} ${user.lastName ?? ''}`}</Text>
			<Text mt="3" fontWeight={'light'}>
				{helperText}
			</Text>
			<Tabs index={activeTabIndex ?? 0} mt="10" isLazy variant={'unstyled'}>
				<TabList borderBottom={0}>
					<Tab
						onClick={() => setActiveTabIndex(null)}
						fontWeight={'medium'}
						_selected={{ color: '#4241E4' }}
						fontSize={'16px'}
					>
						{t('personal_settings')}
					</Tab>
					{managedOrgId && (
						<Tab
							onClick={() => setActiveTabIndex(1)}
							fontWeight={'medium'}
							_selected={{ color: '#4241E4' }}
							fontSize={'16px'}
						>
							{t('team_settings')}
						</Tab>
					)}
				</TabList>
				<TabIndicator
					mt="-1.5px"
					height="2px"
					bg="#4241E4"
					borderRadius="1px"
					key={language}
				/>
				<TabPanels>
					<TabPanel px="0">
						<PersonalSettingsTab />
					</TabPanel>
					{managedOrgId && (
						<TabPanel px="0">
							<CompanySettingsTab />
						</TabPanel>
					)}
				</TabPanels>
			</Tabs>
		</AppLayout>
	);
};
