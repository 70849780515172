import {
	Box,
	Flex,
	SimpleGrid,
	Skeleton,
	SkeletonText,
	Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StoryCard } from '../../../common/components/StoryCard';
import { useGetUserGymStoriesQuery } from '../../../redux/api/cmsApi';

const LoadingState = () => {
	return (
		<Flex direction={'column'} gap="4" aria-label="Loading stories">
			<Box maxW="300px">
				<SkeletonText noOfLines={1} height={'30px'} />
			</Box>
			<Flex gap="4" alignItems={'center'}>
				<Skeleton borderRadius={'8px'} h="350px" flex={1} />
				<Skeleton borderRadius={'8px'} h="350px" flex={1} />
				<Skeleton borderRadius={'8px'} h="350px" flex={1} />
			</Flex>
		</Flex>
	);
};

export const AllStories = () => {
	const { t } = useTranslation('gym');
	const navigate = useNavigate();
	const { data: stories, isLoading } = useGetUserGymStoriesQuery();

	if (isLoading) {
		return <LoadingState />;
	}

	if (!stories) {
		return null;
	}
	return (
		<Box my="10">
			<Flex mb="6" alignItems={'center'} gap="2">
				<Text
					w="fit-content"
					fontSize="2xl"
					color={'#757575'}
					fontWeight="semibold"
				>
					{t('all_missions', { count: stories.length })}
				</Text>
			</Flex>
			<SimpleGrid spacing={5} columns={[1, 1, 2, 3]}>
				{stories?.map((story) => (
					<StoryCard
						storyId={story.id}
						onCardClick={() => {
							navigate(`/call?storyId=${story.id}&ref=gym`);
						}}
						key={story.id}
						type="Challenge"
						person={story.persona.name}
						image={
							story.persona.avatar_file.sizes.thumbnail?.url ??
							story.persona.avatar_file.url
						}
						title={story.title}
						role={`${story.persona.role} ${story.persona.workplace_name && `@${story.persona.workplace_name}`}`}
						tags={[]}
						showStatus
					/>
				))}
			</SimpleGrid>
		</Box>
	);
};
