import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { Chip } from '../../../../../common/components/Chip';
import { ArrowDown, ArrowRight, ArrowUp, Info } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';

type CompletionStatProps = {
	title: string;
	hint: string;
	delta?: number;
	completions: number;
};

const NUMBER_TO_STYLES = {
	positive: {
		color: '#147129',
		bg: '#EAFDEE',
		Icon: <ArrowUp size={10} />,
	},
	negative: {
		color: '#B91C1C',
		bg: '#FDEEEE',
		Icon: <ArrowDown size={10} />,
	},
	neutral: {
		color: '#B91C1C',
		bg: '#FDEEEE',
		Icon: <ArrowRight size={10} />,
	},
};

export const CompletionStat = ({
	title,
	completions,
	hint,
	delta,
}: CompletionStatProps) => {
	const { t } = useTranslation('dashboard');
	let styles = NUMBER_TO_STYLES.neutral;
	if (typeof delta == 'number') {
		styles =
			delta > 0
				? NUMBER_TO_STYLES.positive
				: delta < 0
					? NUMBER_TO_STYLES.negative
					: NUMBER_TO_STYLES.neutral;
	}

	return (
		<Box
			border={'1px'}
			borderColor={'#d9d9d9'}
			borderRadius={'16px'}
			padding={6}
		>
			<Flex direction={'column'} gap="3">
				<Flex gap="1" alignItems={'center'}>
					<Text fontSize={'14px'} color="#0B1215">
						{title}
					</Text>
					<Tooltip label={hint}>
						<Info color="#D9D9D9" size={20} />
					</Tooltip>
				</Flex>
				<Flex alignItems={'flex-end'} justifyContent={'space-between'}>
					<Text fontSize={'40px'} color="#0B1215">
						{completions}
					</Text>
					{typeof delta == 'number' && (
						<Chip
							mb="3"
							gap={1}
							border={0}
							icon={styles.Icon}
							bg={styles.bg}
							color={styles.color}
							text={t('team_activity_section.last_week', { delta }) ?? ''}
						/>
					)}
				</Flex>
			</Flex>
		</Box>
	);
};
