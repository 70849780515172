import { useActiveContentAllocationId } from '../../../common/hooks/useActiveContentAllocationId';
import { useGetJourneyDetailsQuery } from '../../../redux/api/cmsApi';
import {
	useGetRefreshersQuery,
	useGetUserJourneyContentAllocationQuery,
} from '../../../redux/api/learnerApi';
import { useActiveStory } from '../../home/hooks/useActiveStory';
import { useReferer } from '../../home/hooks/useReferer';

type NextStepAction = {
	action: 'nextStory' | 'back';
	nextStoryUrl: string;
};

const getBackNextUrl = (ref: string | null) => {
	if (ref === 'home') {
		return '/';
	}
	if (ref === 'gym') {
		return '/gym';
	}
	if (ref === 'history') {
		return '/history';
	}
	return `/users/history`;
};

export const useNextStep = (): NextStepAction | undefined => {
	const { activeStory } = useActiveStory();
	const { ref } = useReferer();
	const backNextUrl = getBackNextUrl(ref);
	const { activeContentAllocationId } = useActiveContentAllocationId();
	const { data: userJourney } = useGetUserJourneyContentAllocationQuery();
	const { data: journeyDetails } = useGetJourneyDetailsQuery(
		userJourney?.journeyReferenceId ?? '',
		{
			skip: !userJourney,
		}
	);
	const { data: refreshers } = useGetRefreshersQuery(100);

	const flattenStories = journeyDetails?.tracks.flatMap(
		(track) => track.stories
	);

	if (!journeyDetails || !userJourney || !refreshers || !flattenStories) {
		return undefined;
	}

	if (!activeContentAllocationId) {
		return {
			action: 'back',
			nextStoryUrl: backNextUrl,
		};
	}

	if (activeContentAllocationId === userJourney.id) {
		const currentStoryIndex = flattenStories?.findIndex(
			(story) => story.id === activeStory
		);
		// get next story, if index is last return null
		const nextStoryId =
			currentStoryIndex === flattenStories.length - 1
				? undefined
				: flattenStories[currentStoryIndex + 1].id;
		return {
			action: nextStoryId ? 'nextStory' : 'back',
			nextStoryUrl: nextStoryId
				? `call?storyId=${nextStoryId}&contentAllocationId=${activeContentAllocationId}&ref=${ref ?? ''}&track=${
						journeyDetails.tracks.find((track) =>
							track.stories.find((s) => s.id === nextStoryId)
						)?.title ?? ''
					}`
				: backNextUrl,
		};
	}

	const isRefresher = refreshers.find(
		(refresher) => refresher.id === activeContentAllocationId
	);

	if (isRefresher) {
		const currentStoryIndex = refreshers.findIndex(
			(r) => r.storyReferenceId === activeStory
		);
		const nextStory =
			currentStoryIndex === refreshers.length - 1
				? undefined
				: refreshers[currentStoryIndex + 1];
		return {
			action: nextStory ? 'nextStory' : 'back',
			nextStoryUrl: nextStory
				? `call?storyId=${nextStory.storyReferenceId}&contentAllocationId=${nextStory.id}&ref=${ref ?? ''}`
				: backNextUrl,
		};
	}
};
