import {
	Box,
	Button,
	Divider,
	Flex,
	Image,
	Progress,
	Skeleton,
	SkeletonText,
	Stack,
	Text,
} from '@chakra-ui/react';
import { SessionResponseDTOAnalysisItemsSolved } from '@jam/api-sdk';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Chip } from '../../../common/components/Chip';
import { XpIcon } from '../../../common/components/Icons';
import { StoryLabels } from '../../../common/components/StoryLabels';
import { useStoryStatus } from '../../../common/hooks/useStoryStatus';
import { useGetFirstNotCompletedStory } from '../../../common/utils/getfirstNotCompletedStory';
import { useGetStoryLastSessionQuery } from '../../../redux/api/learnerApi';

type NextStoryProps = {
	page: string;
};

const LoadingState = () => {
	return (
		<Flex direction={'column'} gap="6">
			<Box maxW="300px">
				<SkeletonText noOfLines={1} height={'30px'} />
			</Box>
			<Flex gap="4" alignItems={'center'}>
				<Skeleton borderRadius={'8px'} h="90px" w="120px" size="10" />
				<SkeletonText w={'full'} noOfLines={4} spacing={'4'} />
			</Flex>
		</Flex>
	);
};

export const NextStory = ({ page }: NextStoryProps) => {
	const { t } = useTranslation();
	const navigate: NavigateFunction = useNavigate();

	const activeStory = useGetFirstNotCompletedStory();
	const status = useStoryStatus(activeStory?.story.id ?? '');

	const { data: lastSession } = useGetStoryLastSessionQuery(
		activeStory?.story.id ?? '',
		{
			skip: !activeStory,
		}
	);

	if (activeStory === null) {
		return null;
	}
	if (activeStory === undefined) {
		return <LoadingState />;
	}
	const { story, track, contentAllocationId } = activeStory;

	let buttonText = '';
	if (status === 'IN_PROGRESS') {
		buttonText = t('resume');
	} else {
		buttonText = t('start');
	}

	const onCall = () =>
		navigate(
			`/call?storyId=${story.id}&contentAllocationId=${contentAllocationId ?? ''}&ref=${page}&track=${track.title}`
		);

	const analysis = lastSession?.analysis;
	const completedItems =
		analysis?.items.filter(
			(item) => item.solved === SessionResponseDTOAnalysisItemsSolved.solved
		).length || 0;
	const allItems = analysis?.items.length || 0;

	return (
		<Box>
			<Flex mb="6" alignItems={'center'} gap="2">
				<Text
					w="fit-content"
					fontSize="2xl"
					color={'#757575'}
					fontWeight="semibold"
				>
					{t('your_next_mission')}
				</Text>
			</Flex>
			<Box
				borderRadius={'32px'}
				p="4"
				bg="#F7F5FF"
				border={'1px'}
				borderColor={'#D9D9D9'}
			>
				<Flex justifyContent={'space-between'}>
					<Flex gap="3">
						<Box w="100px" h="100px">
							<Image
								borderRadius={'12px'}
								src={story.persona.avatar_file?.url}
							/>
						</Box>
						<Box>
							<Text fontSize={'24px'} fontWeight={'medium'}>
								{story.title}
							</Text>
							<Text
								fontSize={'12px'}
								color="#757575"
								textDecoration={'underline'}
								cursor={'pointer'}
								onClick={() => navigate(`/gym`)}
							>
								{track.title}
							</Text>
							<Stack
								divider={<Divider orientation="vertical" h="30px" />}
								alignItems={'center'}
								direction="row"
								spacing={2}
								mt="2"
							>
								<Text fontSize={'14px'} color="#757575">
									{story.persona.name}
								</Text>
								<Text fontSize={'14px'} color="#757575">
									{story.persona.role}{' '}
									{story.persona.workplace_name &&
										`@${story.persona.workplace_name}`}
								</Text>
								<StoryLabels story={story} hideXp />
								{story.session_xp && story.story_xp && (
									<Chip
										bg="transparent"
										border={0}
										minH={'28px'}
										icon={<XpIcon />}
										text={`${story.session_xp} | ${story.story_xp}`}
										aria-label="Story experience points"
									/>
								)}
							</Stack>
						</Box>
					</Flex>
					<Box>
						<Button
							id="next-story-start-button"
							borderRadius={'32px'}
							textColor={'white'}
							bg={'#4241E4'}
							gap={'16px'}
							_hover={{ bg: '#4241E4' }}
							onClick={onCall}
						>
							<Text>{buttonText}</Text>
							<Image src="/icons/right-arrow.svg" />
						</Button>
					</Box>
				</Flex>
				{allItems > 0 && (
					<Flex mt="4" gap={'2'} alignItems={'center'}>
						<Chip
							display={'inline-block'}
							bg={'#D5C9FF'}
							text={`${completedItems}/${allItems}`}
						/>
						<Box w="100%">
							<Progress
								border={'1px'}
								borderColor={'#D9D9D9'}
								borderRadius={'25px'}
								size={'lg'}
								value={completedItems}
								max={allItems}
							/>
						</Box>
					</Flex>
				)}
			</Box>
		</Box>
	);
};
