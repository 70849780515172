import {
	Box,
	Flex,
	Popover,
	PopoverContent,
	PopoverTrigger,
	Skeleton,
	Stack,
	Text,
} from '@chakra-ui/react';
import {
	SchemaSessionResponseDto,
	SessionResponseDTOAnalysisItemsSolved,
} from '@jam/api-sdk';
import { createColumnHelper } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { Chip } from '../../../../common/components/Chip';
import CustomTable from '../../../../common/components/custom-table/CustomTable';
import { useGetUserStoriesQuery } from '../../../../redux/api/cmsApi';
import { useGetUserSessionsQuery } from '../../../../redux/api/dashboardApi';
import { SolvedStatusToColorMap } from '../../../user-history/components/HistoryTable';

const LoadingState = () => {
	return (
		<Flex direction={'column'} gap="4">
			<Box maxW="300px">
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
			</Box>
			<Box display={'flex'} flexDirection={'column'} h="200px" w="100%" gap={2}>
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
			</Box>
		</Flex>
	);
};

export const UserDetailsHistory = () => {
	const columnHelper = createColumnHelper<SchemaSessionResponseDto>();
	const { userId } = useParams<{ userId: string }>();
	const { data, isLoading } = useGetUserSessionsQuery(userId ?? '', {
		skip: !userId,
	});
	const { data: stories, isLoading: storiesLoading } = useGetUserStoriesQuery();

	if (isLoading || storiesLoading) return <LoadingState />;
	if (!data || !stories) return null;

	const columns = [
		columnHelper.accessor('storyReferenceId', {
			header: t('common:mission').toString(),
			cell: ({ row }) => {
				const story = stories.find(
					(x) => x.id === row.original.storyReferenceId
				);
				if (!story) return '-';

				return (
					<Text maxW={'200px'} isTruncated>
						{story.title}
					</Text>
				);
			},
		}),
		columnHelper.accessor('createdAt', {
			header: t('common:date-time').toString(),
			cell: ({ row }) => {
				if (!row.original.createdAt) return null;
				return (
					<Text>{formatDate(row.original.createdAt, 'MMMM d (HH:mm)')}</Text>
				);
			},
		}),
		columnHelper.accessor('state', {
			header: t('common:status').toString(),
			cell: ({ row }) => {
				if (!row.original.createdAt) return null;
				return (
					<Chip
						border={0}
						bg="#F5F5F5"
						color="#757575"
						text={row.original.state}
					/>
				);
			},
		}),
		columnHelper.display({
			header: t('common:results').toString(),
			cell: ({ row }) => {
				const showMax = 3;
				const results = row.original.analysis.items.map(
					(x) => x.solved ?? SessionResponseDTOAnalysisItemsSolved.undetected
				);
				const areMany = results.length > showMax;
				const showResults = areMany ? results.slice(0, showMax - 1) : results;
				return (
					<Popover placement="top">
						<PopoverTrigger>
							<Flex alignItems={'center'} w="fit-content">
								{showResults.map((result, index) => {
									return (
										<Box
											ml="-2"
											w="22px"
											h="22px"
											bg={SolvedStatusToColorMap[result]}
											borderRadius={'full'}
											borderWidth={2}
											borderColor={'white'}
											key={index}
										/>
									);
								})}
								{areMany && (
									<Box
										ml="-2"
										bg={'black'}
										px="1"
										borderRadius={'full'}
										fontSize={'11px'}
										color={'white'}
										borderWidth={2}
										borderColor={'white'}
									>
										+{results.length - showMax + 1}
									</Box>
								)}
							</Flex>
						</PopoverTrigger>
						<PopoverContent px="4" py="2" w="fit-content">
							<Flex alignItems={'center'}>
								{results.map((result, index) => {
									return (
										<Box
											ml="-2"
											w="22px"
											h="22px"
											bg={SolvedStatusToColorMap[result]}
											borderRadius={'full'}
											borderWidth={2}
											borderColor={'white'}
											key={index}
										/>
									);
								})}
							</Flex>
						</PopoverContent>
					</Popover>
				);
			},
		}),
	];
	return (
		<Flex gap={6} direction={'column'}>
			<Stack mt="5" direction="column" spacing="5">
				<CustomTable<SchemaSessionResponseDto>
					data={data}
					columns={columns}
					defaultSorting={[{ id: 'createdAt', desc: true }]}
					tableProps={{
						border: '1px solid #D9D9D9',
					}}
					headerProps={{
						bg: '#F5F5F5',
					}}
				/>
			</Stack>
		</Flex>
	);
};
