import { Box, Flex, SimpleGrid, Skeleton, Text } from '@chakra-ui/react';
import { TrackItem } from './track/TrackItem';
import { useTranslation } from 'react-i18next';
import { useGetUserJourneyWithSessions } from '../../../hooks/useGetUserJourneyWithSessions';
import { useParams } from 'react-router-dom';

const LoadingState = () => {
	return (
		<Flex direction={'column'} gap="4">
			<Box maxW="300px">
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
			</Box>
			<SimpleGrid mt="6" columns={1} gap={10} h={'100%'}>
				<Skeleton borderRadius={'8px'} h="150px" flex={1} />
				<Skeleton borderRadius={'8px'} h="150px" flex={1} />
				<Skeleton borderRadius={'8px'} h="150px" flex={1} />
				<Skeleton borderRadius={'8px'} h="150px" flex={1} />
			</SimpleGrid>
		</Flex>
	);
};

export const JourneyProgress = () => {
	const { t } = useTranslation('common');
	const { userId } = useParams();
	const data = useGetUserJourneyWithSessions(userId);
	if (data === undefined) return <LoadingState />;
	if (data.length === 0) return null;
	return (
		<Flex direction="column" gap="5">
			<Text fontSize="24px" fontWeight="500">
				{t('journey_progress')}
			</Text>
			<Flex direction="column" gap="5">
				{data.map((track) => (
					<TrackItem track={track} key={track.title} />
				))}
			</Flex>
		</Flex>
	);
};
