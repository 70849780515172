import {
	Flex,
	Divider,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	Link as ChakraLink,
	Box,
	Text,
} from '@chakra-ui/react';
import { SchemaJourneyResponseDto } from '@jam/api-sdk';
import { Users, CaretUp, CaretDown, Clock, Phone } from '@phosphor-icons/react';
import { Chip } from '../../../common/components/Chip';
import { Link as ReactRouterLink } from 'react-router-dom';
import { TaskLineItem } from './TaskLineItem';
import { useTranslation } from 'react-i18next';

type MissionDetailProps = {
	trackId: string;
	mission: SchemaJourneyResponseDto['tracks'][0]['stories'][0];
};

export const MissionCard = ({ mission, trackId }: MissionDetailProps) => {
	const { t } = useTranslation('story');

	return (
		<Flex
			w={'100%'}
			p={6}
			borderWidth={'1.5px'}
			borderRadius={'24px'}
			borderColor={'#D9D9D9'}
			direction={'column'}
		>
			<ChakraLink
				fontSize={'20px'}
				fontWeight={'500'}
				color={'#0B1215'}
				as={ReactRouterLink}
				to={`/track/${trackId}/mission/${mission.id}`}
				variant={''}
				_hover={{ textDecoration: 'underline' }}
			>
				{mission.title}
			</ChakraLink>
			{mission.conversation_topic.length > 0 && (
				<Flex mt={3} gap={2}>
					{mission.conversation_topic.map((topic) => (
						<Chip
							bg={'#FAFAFA'}
							fontSize={'13px'}
							color={'#757575'}
							text={topic}
							key={topic}
						/>
					))}
				</Flex>
			)}
			<Flex mt="5">
				<Chip
					gap={1}
					bg={'transparent'}
					border={0}
					color={'#757575'}
					icon={
						mission.situation.conversation_type === 'call' ? (
							<Phone size={16} />
						) : (
							<Users size={16} />
						)
					}
					text={t(mission.situation.conversation_type)}
				/>
				<Chip
					gap={1}
					bg={'transparent'}
					border={0}
					color={'#757575'}
					text={t(mission.difficulty)}
				/>
				<Chip
					gap={1}
					bg={'transparent'}
					border={0}
					color={'#757575'}
					icon={<Clock size={16} />}
					text={`${mission.duration} mins`}
				/>
			</Flex>
			<Divider my="2" />
			<Accordion allowMultiple>
				<AccordionItem border={0}>
					{({ isExpanded }) => (
						<Box>
							<AccordionButton
								px={0}
								mx={0}
								_hover={{ background: 'transparent' }}
								w="100%"
							>
								<Flex alignItems={'center'} w="100%" gap={2}>
									{isExpanded ? (
										<CaretUp size={20} weight="bold" />
									) : (
										<CaretDown size={20} weight="bold" />
									)}
									<Text fontSize="16px" fontWeight="500">
										{mission.items_conversation_feedback.length} tasks
									</Text>
								</Flex>
							</AccordionButton>
							<AccordionPanel px={0} mx={0}>
								<Flex direction={'column'}>
									{mission.items_conversation_feedback.map((item, index) => (
										<TaskLineItem
											key={item.scorecard_title}
											missionId={mission.id}
											trackId={trackId}
											task={item}
											index={index}
										/>
									))}
								</Flex>
							</AccordionPanel>
						</Box>
					)}
				</AccordionItem>
			</Accordion>
		</Flex>
	);
};
