import {
	Box,
	Button,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Skeleton,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { CaretDown, Info, LockSimple } from '@phosphor-icons/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
	Bar,
	BarChart,
	CartesianGrid,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from 'recharts';
import { Chip } from '../../../../../common/components/Chip';
import { useGetDetailedUserWeeklyActivityQuery } from '../../../../../redux/api/dashboardApi';

const filterOptions = [4, 8, 16];

const BarShape = (props: Record<string, number | string>) => {
	const { t } = useTranslation();
	const { x, y, width, height, name, currentWeek, value } = props;
	const isCurrentWeek = name === currentWeek;

	if (isCurrentWeek) {
		return (
			<Tooltip
				placement={'top'}
				label={t('call:rounds_played', { count: value as number })}
			>
				<rect
					x={x}
					y={y}
					width={width}
					height={height}
					fill="#4241E4"
					rx="14"
					fillOpacity="0.08"
					stroke="#4241E4"
					strokeOpacity="0.3"
					strokeWidth="2"
					strokeDasharray="4 4"
				/>
			</Tooltip>
		);
	}
	return (
		<Tooltip
			placement={'top'}
			label={t('call:rounds_played', { count: value as number })}
		>
			<rect
				x={x}
				y={y}
				width={width}
				height={height}
				rx="14"
				fill="#4241E4"
				fillOpacity="0.3"
				strokeOpacity="0.3"
				strokeWidth="2"
				strokeDasharray="4 4"
			/>
		</Tooltip>
	);
};

const LoadingState = () => {
	return (
		<Flex direction={'column'} gap="4">
			<Box maxW="300px">
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
			</Box>
			<Flex gap="4" alignItems={'center'}>
				<Skeleton borderRadius={'8px'} h="350px" flex={1} />
				<Skeleton borderRadius={'8px'} h="350px" flex={1} />
				<Skeleton borderRadius={'8px'} h="350px" flex={1} />
				<Skeleton borderRadius={'8px'} h="350px" flex={1} />
				<Skeleton borderRadius={'8px'} h="350px" flex={1} />
			</Flex>
		</Flex>
	);
};

export const WeeklyActivity = () => {
	const { t } = useTranslation('dashboard');
	const [filter] = useState(filterOptions[0]);
	const { userId } = useParams();
	const { data: weeklyData, isLoading } = useGetDetailedUserWeeklyActivityQuery(
		userId as string,
		{
			skip: !userId,
		}
	);

	if (isLoading) return <LoadingState />;
	if (!weeklyData) return null;

	const keys = Object.keys(weeklyData).sort();
	const lastWeekKey = keys[keys.length - 2];
	const currentWeekKey = keys[keys.length - 1];
	const regex = /W(\d+)/;
	const lastWeekValue = weeklyData[lastWeekKey];

	const data = keys.map((key) => ({
		name: t('detailed_user_view.week_number', {
			weekNumber: key.match(regex)?.[1],
		}),
		value: weeklyData[key],
	}));

	const currentWeek = t('detailed_user_view.week_number', {
		weekNumber: currentWeekKey.match(regex)?.[1],
	});

	return (
		<Box>
			<Text fontSize={'24px'} fontWeight={'500'} color={'#0B1215'}>
				{t('weekly_activity')}
			</Text>
			<Box
				mt={6}
				border={'1px'}
				borderColor={'#d9d9d9'}
				borderRadius={'16px'}
				padding={6}
			>
				<Flex direction={'column'} gap="3">
					<Flex justify={'space-between'} alignItems={'center'}>
						<Flex direction={'column'} gap="3">
							<Flex gap="1" alignItems={'center'}>
								<Text fontSize={'14px'} color="#0B1215">
									{t('detailed_user_view.rounds_completed')}
								</Text>
								<Tooltip label={t('detailed_user_view.role_play_sessions')}>
									<Info color="#D9D9D9" size={20} />
								</Tooltip>
							</Flex>
							<Flex alignItems={'flex-end'}>
								<Text fontSize={'40px'} color="#0B1215">
									{lastWeekValue}
								</Text>
								<Chip
									mb="3"
									px={2}
									border={0}
									bg="#FAFAFA"
									color={'#757575'}
									text={t('detailed_user_view.last_week_label')}
								/>
							</Flex>
						</Flex>
						<Menu>
							<MenuButton
								as={Button}
								color={'#0B1215'}
								bg={'#F5F5F5'}
								_hover={{ bg: '#F5F5F5' }}
								rightIcon={<CaretDown />}
							>
								{t('weeks_time', { weeks: filter })}
							</MenuButton>
							<MenuList>
								{filterOptions.map((option) => (
									<MenuItem
										cursor={'not-allowed'}
										key={option}
										icon={<LockSimple />}
									>
										{t('weeks_time', { weeks: option })}
									</MenuItem>
								))}
							</MenuList>
						</Menu>
					</Flex>
					<ResponsiveContainer width={'100%'} height={200}>
						<BarChart data={data}>
							<CartesianGrid vertical={false} />
							<XAxis dataKey="name" axisLine={false} tickLine={false} />
							<YAxis
								allowDecimals={false}
								width={50}
								axisLine={false}
								tickLine={false}
							/>
							<Bar
								barSize={30}
								dataKey="value"
								shape={<BarShape currentWeek={currentWeek} />}
							/>
						</BarChart>
					</ResponsiveContainer>
				</Flex>
			</Box>
		</Box>
	);
};
