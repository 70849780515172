import { Divider, Stack } from '@chakra-ui/react';
import { useUserOrg } from '../../../common/hooks/useUserOrg';
import { PendingInvites } from './PendingInvites';
import { UsersTable } from './UsersTable';

export const CompanySettingsTab = () => {
	const org = useUserOrg();

	if (!org) return null;

	return (
		<Stack direction="column" spacing="5" divider={<Divider />} py="10">
			<PendingInvites orgId={org.orgId} />
			<UsersTable />
		</Stack>
	);
};
