import { Suspense, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
	Navigate,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
// import i18n to bundle it
import './i18n';

import * as Sentry from '@sentry/react';
import ErrorPage from './pages/ErrorPage';

import { ChakraProvider } from '@chakra-ui/react';
import { WhoAmIResponseRole } from '@jam/api-sdk';
import { RedirectToLogin, RequiredAuthProvider } from '@propelauth/react';
import { Provider } from 'react-redux';
import { RoleCheck } from './common/hoc/RoleCheck';
import { initLogger } from './common/utils/logger';
import AuthPage from './pages/AuthPage';
import { CallPage } from './pages/CallPage';
import { GymPage } from './pages/GymPage';
import { HomePage } from './pages/HomePage';
import { LayoutPage } from './pages/LayoutPage';
import { SettingsPage } from './pages/SettingsPage';
import { UserHistoryPage } from './pages/UserHistoryPage';
import { ManagerDashboard } from './pages/dashboard/ManagerDashboard';
import { ManagerDashboardUserDetails } from './pages/dashboard/ManagerDashboardUserDetails';
import { ManagerMissionDetailPage } from './pages/journey-explorer/ManagerMissionDetailPage';
import { ManagerTrackDetailsPage } from './pages/journey-explorer/ManagerTrackDetailsPage';
import { ManagerTracksPage } from './pages/journey-explorer/ManagerTracksPage';
import store from './redux/store';
import { customTheme } from './theme/chakraTheme';
import {
	currentEnvNeedsAmplitude,
	currentEnvNeedsSentry,
	getReactAppEnv,
} from './utils';

const authUrl = process.env.REACT_APP_AUTH_URL ?? '';

let createBrowserRouterFunction = createBrowserRouter;

if (currentEnvNeedsAmplitude()) {
	initLogger();
}

if (currentEnvNeedsSentry()) {
	Sentry.init({
		dsn: 'https://a4bf319560dd4cf3e9b63390b3f794db@o4506314947297280.ingest.sentry.io/4506314953523200',
		environment: getReactAppEnv(),
		integrations: [
			new Sentry.BrowserTracing({
				// See docs for support of different versions of variation of react router
				// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
				routingInstrumentation: Sentry.reactRouterV6Instrumentation(
					useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes
				),
			}),
			new Sentry.Replay(),
		],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		tracesSampleRate: 1.0,
		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});

	createBrowserRouterFunction =
		Sentry.wrapCreateBrowserRouter(createBrowserRouter);

	// we need the listeners if we want to catch exceptions not caught by the ErrorBoundary
	window.addEventListener('error', (e: ErrorEvent) => {
		//Prevents double throwing of errors, a React problem
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		if (e.error.hasBeenCaught !== undefined) {
			return false;
		}
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		e.error.hasBeenCaught = true;
		Sentry.captureException(e);
	});

	window.addEventListener('unhandledrejection', (e: PromiseRejectionEvent) =>
		Sentry.captureException(e)
	);
}

const router = createBrowserRouterFunction([
	{
		path: '/',
		element: <LayoutPage />,
		children: [
			{
				index: true,
				element: <Navigate to="/users/home" replace />,
			},
			{
				path: '/settings',
				element: <SettingsPage />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/users/home',
				element: <HomePage />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/tracks',
				element: <ManagerTracksPage />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/track/:trackId',
				element: <ManagerTrackDetailsPage />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/track/:trackId/mission/:missionId',
				element: <ManagerMissionDetailPage />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/dashboard',
				element: (
					<RoleCheck
						requiredRoles={[
							WhoAmIResponseRole.Manager,
							WhoAmIResponseRole.Owner,
						]}
						WrappedComponent={<ManagerDashboard />}
					/>
				),
				errorElement: <ErrorPage />,
			},
			{
				path: '/dashboard/user/:userId',
				element: (
					<RoleCheck
						requiredRoles={[
							WhoAmIResponseRole.Manager,
							WhoAmIResponseRole.Owner,
						]}
						WrappedComponent={<ManagerDashboardUserDetails />}
					/>
				),
				errorElement: <ErrorPage />,
			},
			{
				path: '/users/history',
				element: <UserHistoryPage />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/call',
				element: <CallPage />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/gym',
				element: <GymPage />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/auth',
				element: <AuthPage loading={true} />,
				errorElement: <ErrorPage />,
			},
			{
				path: '*',
				element: <Navigate to="/users/home" replace />,
				errorElement: <ErrorPage />,
			},
		],
	},
]);

// render react into root dom element
const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<Provider store={store}>
		<Sentry.ErrorBoundary>
			<ChakraProvider theme={customTheme}>
				<Suspense fallback="loading">
					<RequiredAuthProvider
						authUrl={authUrl}
						displayWhileLoading={<h1>Loading...</h1>}
						displayIfLoggedOut={
							<RedirectToLogin postLoginRedirectUrl={window.location.href} />
						}
					>
						<RouterProvider router={router} fallbackElement={<ErrorPage />} />
					</RequiredAuthProvider>
				</Suspense>
			</ChakraProvider>
		</Sentry.ErrorBoundary>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an manager-analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
