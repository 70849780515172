import {
	SchemaCompletedUserActivityReposeDto,
	SchemaCompletionsWithTrendsResponseDto,
	SchemaDetailedUserOverviewResponseDto,
	SchemaJourneyContentAllocationStatusResponseDto,
	SchemaKeyStoryForImprovementDto,
	SchemaSessionResponseDto,
	SchemaSessionsTeamActivityReposeDto,
	SchemaUserActivityReposeDto,
	SchemaWeekDataNumberResponseDto,
	SchemaWeeklyActivityAcrossAllUsersResponseDto,
} from '@jam/api-sdk';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

const api = createApi({
	reducerPath: 'dashboard-api',
	baseQuery: fetchBaseQuery({
		baseUrl: `${
			process.env
				.REACT_APP_SALES_ENABLEMENT_PLATFORM_NEST_API_BASE_URL as string
		}/api/v1`,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).applicationState.accessToken;

			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}

			return headers;
		},
	}),
	endpoints: (builder) => ({
		getSessionActivity: builder.query<
			SchemaCompletedUserActivityReposeDto,
			void
		>({
			query: () => 'user-activity/session-activity',
		}),
		getUsageInsights: builder.query<SchemaUserActivityReposeDto[], void>({
			query: () => 'user-activity/activity-per-user',
		}),
		getTeamCompletions: builder.query<
			SchemaCompletionsWithTrendsResponseDto,
			void
		>({
			query: () => 'team-activity/completions',
		}),
		getWeeklyActivity: builder.query<
			SchemaWeeklyActivityAcrossAllUsersResponseDto,
			void
		>({
			query: () => 'team-activity/weekly-activity',
		}),
		getRolePlayActivity: builder.query<
			SchemaSessionsTeamActivityReposeDto[],
			void
		>({
			query: () => 'team-activity/all-sessions',
		}),
		getDetailedUserKeyMissionsForImprovement: builder.query<
			SchemaKeyStoryForImprovementDto[],
			string
		>({
			query: (userId: string) =>
				`detailed-user-activity/key-stories?userId=${userId}`,
		}),
		getDetailedUserActivityOverview: builder.query<
			SchemaDetailedUserOverviewResponseDto,
			string
		>({
			query: (userId: string) =>
				`detailed-user-activity/overview?userId=${userId}`,
		}),
		getDetailedUserWeeklyActivity: builder.query<
			SchemaWeekDataNumberResponseDto,
			string
		>({
			query: (userId: string) =>
				`detailed-user-activity/weekly-activity?userId=${userId}`,
		}),
		getDetailedUserCompletions: builder.query<
			SchemaCompletionsWithTrendsResponseDto,
			string
		>({
			query: (userId: string) =>
				`detailed-user-activity/completions?userId=${userId}`,
		}),

		getUserSessions: builder.query<SchemaSessionResponseDto[], string>({
			query: (userId: string) =>
				`detailed-user-activity/sessions?userId=${userId}`,
		}),

		getUserJourneyState: builder.query<
			SchemaJourneyContentAllocationStatusResponseDto,
			{
				userId: string;
				journeyId: string;
			}
		>({
			query: ({ userId, journeyId }: { userId: string; journeyId: string }) =>
				`detailed-user-activity/journey-state?userId=${userId}&journeyId=${journeyId}`,
		}),
	}),
});

export const {
	useGetSessionActivityQuery,
	useGetUsageInsightsQuery,
	useGetTeamCompletionsQuery,
	useGetWeeklyActivityQuery,
	useGetRolePlayActivityQuery,
	useGetDetailedUserActivityOverviewQuery,
	useGetDetailedUserKeyMissionsForImprovementQuery,
	useGetDetailedUserWeeklyActivityQuery,
	useGetDetailedUserCompletionsQuery,
	useGetUserSessionsQuery,
	useGetUserJourneyStateQuery,
} = api;
export default api;
