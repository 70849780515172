import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Box,
	Divider,
	Flex,
	Progress,
	Text,
} from '@chakra-ui/react';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { MissionItem } from './MissionItem';
import { UserTrackWithSessions } from '../../../../hooks/useGetUserJourneyWithSessions';
import { useTranslation } from 'react-i18next';

type TrackItemProps = {
	track: UserTrackWithSessions;
};

export const TrackItem = ({ track }: TrackItemProps) => {
	const { t } = useTranslation('dashboard');
	return (
		<Box p="6" borderRadius={'24px'} border={'1px'} borderColor={'#D9D9D9'}>
			<Text fontSize="20px" fontWeight="500">
				{track.title}
			</Text>
			<Flex mt="4" direction="row" gap="3" alignItems={'center'}>
				<Progress
					border={'1px'}
					size={'md'}
					borderColor={'#D9D9D9'}
					borderRadius={'25px'}
					w={'350px'}
					value={track.completedMissionCount}
					max={track.missionCount}
				/>
				<Text fontSize="14px" fontWeight="500" color={'#4241E4'}>
					{track.completedMissionCount}/{track.missionCount}{' '}
					{t('team_activity_section.missions_completed')}
				</Text>
			</Flex>
			<Divider mt="4" />
			<Accordion allowMultiple>
				<AccordionItem border={0}>
					{({ isExpanded }) => (
						<Box py="6">
							<AccordionButton _hover={{ background: 'transparent' }} w="100%">
								<Flex alignItems={'center'} w="100%" gap={2}>
									<Text fontSize="16px" fontWeight="500" color={'#4241E4'}>
										{t('all_missions')} {track.missionCount}
									</Text>
									{isExpanded ? (
										<CaretUp size={20} weight="bold" color="#4241E4" />
									) : (
										<CaretDown size={20} weight="bold" color="#4241E4" />
									)}
								</Flex>
							</AccordionButton>
							<AccordionPanel>
								<Flex direction={'column'} gap="5" mt="3">
									{track.missions.map((mission) => (
										<MissionItem mission={mission} key={mission.title} />
									))}
								</Flex>
							</AccordionPanel>
						</Box>
					)}
				</AccordionItem>
			</Accordion>
		</Box>
	);
};
