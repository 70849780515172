import {
	Tabs,
	TabList,
	Tab,
	TabIndicator,
	TabPanels,
	TabPanel,
	Text,
	Divider,
	Flex,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AppLayout } from '../../common/components/AppLayout';
import { UserDetailsHistory } from '../../features/dashboard/components/user-details/UserDetailsHistory';
import { UserDetailsOverview } from '../../features/dashboard/components/user-details/UserDetailsOverview';
import { UserSwitcher } from '../../features/dashboard/components/user-details/UserSwitcher';
import { UserWidgets } from '../../common/components/UserWidgets';

export const ManagerDashboardUserDetails = () => {
	const { t } = useTranslation('dashboard', { keyPrefix: 'user_details' });

	return (
		<AppLayout>
			<Flex mt={'-8px'} justifyContent={'space-between'}>
				<Text fontWeight={'medium'} fontSize="40px">
					{t('title')}
				</Text>
				<UserWidgets />
			</Flex>
			<Text fontSize="16px" mt="5" color="#757575">
				{t('helper_text')}
			</Text>
			<UserSwitcher />
			<Divider my="2" />
			<Tabs mt="10" isLazy variant={'unstyled'}>
				<TabList borderBottom={0}>
					<Tab
						fontWeight={'medium'}
						_selected={{ color: '#4241E4' }}
						fontSize={'16px'}
					>
						{t('overview')}
					</Tab>
					<Tab
						fontWeight={'medium'}
						_selected={{ color: '#4241E4' }}
						fontSize={'16px'}
					>
						{t('history')}
					</Tab>
				</TabList>
				<TabIndicator
					mt="-1.5px"
					height="2px"
					bg="#4241E4"
					borderRadius="1px"
				/>
				<TabPanels>
					<TabPanel px="0">
						<UserDetailsOverview />
					</TabPanel>
					<TabPanel px="0">
						<UserDetailsHistory />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</AppLayout>
	);
};
