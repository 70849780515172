import {
	Avatar,
	Button,
	Flex,
	Skeleton,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetDetailedUserActivityOverviewQuery } from '../../../../redux/api/dashboardApi';
import { UserSelectModal } from './overview/UserSelectModal';

const LoadingState = () => {
	return <Skeleton mt="10" borderRadius={'24px'} h="50px" />;
};

export const UserSwitcher = () => {
	const { t } = useTranslation('common');
	const { isOpen, onClose, onOpen } = useDisclosure();

	const { userId } = useParams();
	const { data, isFetching } = useGetDetailedUserActivityOverviewQuery(
		userId as string,
		{
			skip: !userId,
		}
	);

	if (isFetching) return <LoadingState />;

	return (
		<Flex justifyContent="space-between" mt="10">
			<Flex gap={2} alignItems={'center'}>
				{data && (
					<>
						<Avatar
							bg="gray"
							name={data.name}
							size="md"
							src={data.pictureUrl}
						/>
						<Text fontSize="24px" fontWeight="medium">
							{data.name}
						</Text>
					</>
				)}
			</Flex>
			<Button
				_hover={{
					bg: 'brand.500',
				}}
				onClick={onOpen}
				leftIcon={<MagnifyingGlass size="20" />}
			>
				{t('change_user')}
			</Button>
			<UserSelectModal isOpen={isOpen} onClose={onClose} />
		</Flex>
	);
};
