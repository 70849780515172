import {
	Avatar,
	Box,
	Flex,
	IconButton,
	Input,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Stack,
	Text,
} from '@chakra-ui/react';
import {
	DetailedUserOverviewResponseDtoStatus,
	SchemaUserActivityReposeDto,
} from '@jam/api-sdk';
import { Circle, DotsThreeVertical } from '@phosphor-icons/react';
import { createColumnHelper } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Chip } from '../../../../../common/components/Chip';
import CustomTable from '../../../../../common/components/custom-table/CustomTable';
import { useGetUsageInsightsQuery } from '../../../../../redux/api/dashboardApi';

type StatusChipColors = {
	bg: string;
	text: string;
};

export const StatusChipColorsMap: Record<string, StatusChipColors> = {
	[DetailedUserOverviewResponseDtoStatus.ACTIVE_LAST_WEEK]: {
		bg: '#EAFDEE',
		text: '#147129',
	},
	[DetailedUserOverviewResponseDtoStatus.INACTIVE_LAST_WEEK]: {
		bg: 'rgb(251, 226, 90, 0.2)',
		text: '#B69900',
	},
	[DetailedUserOverviewResponseDtoStatus.DISABLED]: {
		bg: '#F5F5F5',
		text: '#757575',
	},
};

export const ActivityPerUserTable = () => {
	const { t } = useTranslation(['dashboard', 'common']);
	const navigate = useNavigate();
	const { data: usage } = useGetUsageInsightsQuery();
	const [search, setSearch] = useState('');
	const customFilter = [{ id: 'name', value: search }];
	const columnHelper = createColumnHelper<SchemaUserActivityReposeDto>();
	const columns = [
		columnHelper.accessor('name', {
			header: t('common:user').toString(),
			cell: ({ row }) => (
				<Flex alignItems={'center'} gap="1">
					<Avatar
						bg="gray"
						size="sm"
						name={row.original.name ?? 'N/A'}
						src={row.original.userPictureUrl}
					/>
					<Text>{row.original.name ?? 'Unknown User'}</Text>
				</Flex>
			),
		}),
		// columnHelper.accessor('team', {
		// 	header: t('common:team').toString(),
		// 	cell: ({ row }) => <Text>{row.original.team}</Text>,
		// }),
		columnHelper.accessor('status', {
			header: t('common:status').toString(),
			cell: ({ row }) => (
				<Chip
					border={0}
					bg={StatusChipColorsMap[row.original.status].bg}
					color={StatusChipColorsMap[row.original.status].text}
					fontWeight="medium"
					gap={1}
					icon={<Circle weight="fill" size={6} />}
					text={t(`common:${row.original.status}`)}
				/>
			),
		}),
		columnHelper.accessor('totalStories', {
			header: t('common:missions').toString(),
			cell: ({ row }) => <Text>{row.original.totalStories}</Text>,
		}),
		columnHelper.accessor('totalSessions', {
			header: t('common:rounds').toString(),
			cell: ({ row }) => <Text>{row.original.totalSessions}</Text>,
		}),
		columnHelper.accessor('sessionsInWeek', {
			header: t('common:last_week_rounds').toString(),
			cell: ({ row }) => <Text>{row.original.sessionsInWeek}</Text>,
		}),
		columnHelper.accessor('lastSessionDate', {
			header: t('common:last_round_date').toString(),
			cell: ({ row }) => {
				if (!row.original.lastSessionDate) return null;
				return (
					<Text>
						{formatDate(row.original.lastSessionDate, 'MMMM d (HH:mm)')}
					</Text>
				);
			},
		}),
		columnHelper.display({
			header: 'Actions',
			meta: { hideTitle: true, name: 'Actions' },
			cell: () => {
				return (
					<Menu>
						<MenuButton
							as={IconButton}
							aria-label="Options"
							icon={<DotsThreeVertical />}
							variant="unstyled"
						/>
						<MenuList>
							<MenuItem onClick={() => {}}>{t('common:see_details')}</MenuItem>
						</MenuList>
					</Menu>
				);
			},
		}),
	];

	if (!usage) return null;

	return (
		<Box>
			<Text color="#757575" fontWeight={'medium'} fontSize="24px">
				{t('activity_per_user')}
			</Text>
			<Stack mt="5" direction="column" spacing="5">
				<Flex gap="5">
					<Input
						maxW={'300'}
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						borderRadius={'8px'}
						placeholder={t('common:search_by_name') ?? ''}
					/>
				</Flex>
				<CustomTable<SchemaUserActivityReposeDto>
					data={usage}
					columns={columns}
					customFilters={customFilter}
					tableProps={{
						border: '1px solid #D9D9D9',
					}}
					onRowClick={(row) => navigate(`/dashboard/user/${row.userId}`)}
					headerProps={{
						bg: '#F5F5F5',
					}}
				/>
			</Stack>
		</Box>
	);
};
