import {
	Box,
	Button,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { SchemaWeeklyActivityAcrossAllUsersResponseDto } from '@jam/api-sdk';
import { CaretDown, Info, LockSimple } from '@phosphor-icons/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Bar,
	BarChart,
	CartesianGrid,
	Legend,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from 'recharts';
import { Chip } from '../../../../../common/components/Chip';

type WeeklyUsersChartProps = {
	userActivity: SchemaWeeklyActivityAcrossAllUsersResponseDto['activeUsers'];
};

const filterOptions = [4, 8, 16];
const CurrentWeekBar = (props: Record<string, any>) => {
	const { t } = useTranslation('dashboard', {
		keyPrefix: 'team_activity_section',
	});
	const { x, y, width, height, fill } = props;
	const payload = props.payload as Record<string, number>;
	return (
		<Tooltip
			placement={'top'}
			label={t('active_users_chart', {
				active: payload.active,
				inactive: payload.inactive,
			})}
		>
			<rect
				x={x as number}
				y={y as number}
				width={width as number}
				height={height as number}
				fill={String(fill)}
				rx="14"
				fillOpacity="0.08"
				stroke={String(fill)}
				strokeOpacity="0.3"
				strokeWidth="2"
				strokeDasharray="4 4"
			/>
		</Tooltip>
	);
};

const BarShape = (props: Record<string, any>) => {
	const { x, y, width, height, name, currentWeek, fill, fillOpacity } = props;
	const isCurrentWeek = name === currentWeek;
	const { t } = useTranslation('dashboard', {
		keyPrefix: 'team_activity_section',
	});

	if (isCurrentWeek) {
		return <CurrentWeekBar {...props} />;
	}
	const payload = props.payload as Record<string, number>;
	return (
		<Tooltip
			placement={'top'}
			label={t('active_users_chart', {
				active: payload.active,
				inactive: payload.inactive,
			})}
		>
			<rect
				x={x as number}
				y={y as number}
				width={width as number}
				height={height as number}
				rx="4"
				fill={String(fill)}
				fillOpacity={fillOpacity as number}
			/>
		</Tooltip>
	);
};

export const WeeklyUsersChart = ({ userActivity }: WeeklyUsersChartProps) => {
	const keys = Object.keys(userActivity).sort();
	const { t } = useTranslation(['dashboard', 'common']);
	const [filter] = useState(filterOptions[0]);
	const lastWeekKey = keys[keys.length - 2];
	const currentWeekKey = keys[keys.length - 1];
	const regex = /W(\d+)/;
	const lastWeekValue = userActivity[lastWeekKey];

	const data = keys.map((key) => ({
		name: t('team_activity_section.week_number', {
			weekNumber: key.match(regex)?.[1],
		}),
		active: userActivity[key].active ?? 0,
		inactive: userActivity[key].inactive ?? 0,
	}));

	const currentWeek = t('team_activity_section.week_number', {
		weekNumber: currentWeekKey.match(regex)?.[1],
	});

	return (
		<Box
			border={'1px'}
			borderColor={'#d9d9d9'}
			borderRadius={'16px'}
			padding={6}
		>
			<Flex direction={'column'} gap="3">
				<Flex justify={'space-between'} alignItems={'center'}>
					<Flex direction={'column'} gap="3">
						<Flex gap="1" alignItems={'center'}>
							<Text fontSize={'14px'} color="#0B1215">
								{t('team_activity_section.active_users')}
							</Text>
							<Tooltip label={t('team_activity_section.active_users_hint')}>
								<Info color="#D9D9D9" size={20} />
							</Tooltip>
						</Flex>
						<Flex alignItems={'flex-end'}>
							<Text fontSize={'40px'} color="#0B1215">
								{lastWeekValue.active}
							</Text>
							<Chip
								mb="3"
								px={2}
								border={0}
								bg="#FAFAFA"
								color={'#757575'}
								text={t('team_activity_section.last_week_label')}
							/>
						</Flex>
					</Flex>
					<Menu>
						<MenuButton
							as={Button}
							color={'#0B1215'}
							bg={'#F5F5F5'}
							_hover={{ bg: '#F5F5F5' }}
							rightIcon={<CaretDown />}
						>
							{t('weeks_time', { weeks: filter })}
						</MenuButton>
						<MenuList>
							{filterOptions.map((option) => (
								<MenuItem
									cursor={'not-allowed'}
									key={option}
									icon={<LockSimple />}
								>
									{t('weeks_time', { weeks: option })}
								</MenuItem>
							))}
						</MenuList>
					</Menu>
				</Flex>
				<ResponsiveContainer width={'100%'} height={250}>
					<BarChart data={data}>
						<CartesianGrid vertical={false} />
						<XAxis dataKey="name" axisLine={false} tickLine={false} />
						<YAxis
							allowDecimals={false}
							width={50}
							axisLine={false}
							tickLine={false}
						/>
						<Legend />
						<Bar
							barSize={30}
							stackId="a"
							dataKey="active"
							name={t('common:active') ?? 'Active'}
							shape={<BarShape currentWeek={currentWeek} />}
							fill="#33D57D"
							fillOpacity={0.4}
						/>
						<Bar
							barSize={30}
							stackId="a"
							dataKey="inactive"
							name={t('common:inactive') ?? 'Inactive'}
							shape={<BarShape currentWeek={currentWeek} />}
							fill="#FBE25A"
							fillOpacity={0.3}
						/>
					</BarChart>
				</ResponsiveContainer>
			</Flex>
		</Box>
	);
};
