import {
	Flex,
	Box,
	IconButton,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { X } from '@phosphor-icons/react';
import { useReferer } from '../../home/hooks/useReferer';
import { useAppSelector } from '../../../redux/hooks';
import {
	selectCurrentCallStartTime,
	setCurrentCallStartTime,
} from '../../../redux/slice';
import { LeaveWarningModal } from './LeaveWarningModal';
import { UserWidgets } from '../../../common/components/UserWidgets';
import { useDispatch } from 'react-redux';

export const CallHeader = () => {
	const { isOpen, onClose, onOpen } = useDisclosure();
	const startTime = useAppSelector(selectCurrentCallStartTime);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { ref } = useReferer();

	let url = '/';
	if (ref) {
		if (ref === 'gym') {
			url = '/gym';
		} else if (ref === 'history') {
			url = '/users/history';
		}
	}

	const onKeepPracticing = () => {
		onClose();
	};

	const onExit = () => {
		onClose();
		dispatch(setCurrentCallStartTime(null));
		navigate(url);
	};

	const onCloseClick = () => {
		if (startTime) {
			onOpen();
			return;
		}
		navigate(url);
	};

	return (
		<Box w="100%" mb="5" borderRadius={'24px'}>
			<LeaveWarningModal
				isOpen={isOpen}
				onExit={onExit}
				onContinue={onKeepPracticing}
			/>
			<Flex gap={10} justifyContent={'space-between'} w="100%">
				<Tooltip
					p={3}
					lineHeight={'21px'}
					hasArrow
					maxW={'200px'}
					placement="right"
					bg={'#0B1215'}
					borderRadius={'12px'}
					fontSize={'12px'}
					border={'1px solid #E2E8F0'}
					label="Close call"
					aria-label="Close call"
				>
					<IconButton
						width={'fit-content'}
						size={'sm'}
						bg="gray.100"
						color={'#0B1215'}
						_hover={{ bg: 'gray.200' }}
						aria-label="Back"
						icon={<X size={20} />}
						onClick={onCloseClick}
					/>
				</Tooltip>
				<UserWidgets />
			</Flex>
		</Box>
	);
};
