import { SchemaJourneyResponseDto } from '@jam/api-sdk';
import {
	JourneyContentAllocationDetails,
	useGetUserJourneyContentAllocationQuery,
} from '../../redux/api/learnerApi';

export const calculateTrackCompletion = (
	track: SchemaJourneyResponseDto['tracks'][0],
	activeJourneyData: JourneyContentAllocationDetails
) => {
	// Get all the completed stories
	const completedStories = activeJourneyData.trackStatuses.flatMap(
		(x) => x.completedStoryIds
	);

	// Filter out the completed stories that are in the track
	const completedStoriesOnTrack = completedStories.filter((storyId) =>
		track.stories.find((story) => story.id === storyId)
	);

	// Calculate the percentage of completion
	const percentage = Math.round(
		(completedStoriesOnTrack.length / track.stories.length) * 100
	);

	// Return the completion data
	return {
		completed: completedStoriesOnTrack.length,
		total: track.stories.length,
		percentage,
	};
};

export const useCalculateTrackCompletion = (
	track: SchemaJourneyResponseDto['tracks'][0] | undefined | null
) => {
	const { data: activeJourneyData } = useGetUserJourneyContentAllocationQuery();
	if (!track || !activeJourneyData) {
		return { completed: 0, total: 0, percentage: 0 };
	}
	return calculateTrackCompletion(track, activeJourneyData);
};
