import { Box, Flex, SimpleGrid, Skeleton, Text } from '@chakra-ui/react';
import { CompletionStat } from './CompletionStat';
import { useGetTeamCompletionsQuery } from '../../../../../redux/api/dashboardApi';
import { useTranslation } from 'react-i18next';

const LoadingState = () => {
	return (
		<Flex direction={'column'} gap="4">
			<Box maxW="300px">
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
			</Box>
			<Flex gap="4" alignItems={'center'}>
				<Skeleton borderRadius={'8px'} h="150px" flex={1} />
				<Skeleton borderRadius={'8px'} h="150px" flex={1} />
				<Skeleton borderRadius={'8px'} h="150px" flex={1} />
				<Skeleton borderRadius={'8px'} h="150px" flex={1} />
			</Flex>
		</Flex>
	);
};

export const CompletionAcrossUsers = () => {
	const { t } = useTranslation('dashboard');
	const { data, isLoading } = useGetTeamCompletionsQuery();
	if (isLoading) return <LoadingState />;
	if (!data) return null;
	return (
		<Flex gap={6} direction={'column'}>
			<Text
				lineHeight={'30px'}
				color="#0B1215"
				fontWeight={'medium'}
				fontSize="24px"
			>
				{t('team_activity_section.completions_title')}
			</Text>
			<SimpleGrid columns={4} spacing={5}>
				<CompletionStat
					title={t('team_activity_section.tasks_completed')}
					hint={t('team_activity_section.tasks_completed_hint')}
					completions={data.overall.storyItemsCompleted}
					delta={data.trends.storyItemsCompleted}
				/>
				<CompletionStat
					title={t('team_activity_section.rounds_completed')}
					hint={t('team_activity_section.rounds_completed_hint')}
					completions={data.overall.sessionsCompleted}
					delta={data.trends.sessionsCompleted}
				/>
				<CompletionStat
					title={t('team_activity_section.missions_completed')}
					hint={t('team_activity_section.missions_completed_hint')}
					completions={data.overall.storiesCompleted}
					delta={data.trends.storiesCompleted}
				/>
				<CompletionStat
					title={t('team_activity_section.tracks_completed')}
					hint={t('team_activity_section.tracks_completed_hint')}
					completions={data.overall.tracksCompleted}
					delta={data.trends.tracksCompleted}
				/>
			</SimpleGrid>
		</Flex>
	);
};
