import { SchemaJourneyResponseDto } from '@jam/api-sdk';
import { useGetJourneyDetailsQuery } from '../../../redux/api/cmsApi';
import { useGetUserJourneyContentAllocationQuery } from '../../../redux/api/learnerApi';

export const useGetCurrentJourney = (): SchemaJourneyResponseDto | null => {
	const { data: userJourney } = useGetUserJourneyContentAllocationQuery();
	const { data: journey } = useGetJourneyDetailsQuery(
		userJourney?.journeyReferenceId || '',
		{
			skip: !userJourney?.journeyReferenceId,
		}
	);

	if (!journey || !userJourney) {
		return null;
	}

	return journey;
};

export const useGetJourneyTrack = (
	trackId: string | undefined
): SchemaJourneyResponseDto['tracks'][0] | null | undefined => {
	const journey = useGetCurrentJourney();

	if (!trackId) {
		return undefined;
	}

	if (!journey) {
		return null;
	}

	return journey.tracks.find((track) => track.id === trackId) || null;
};

export const useGetMission = (
	trackId: string | undefined,
	missionId: string | undefined
): SchemaJourneyResponseDto['tracks'][0]['stories'][0] | null | undefined => {
	const track = useGetJourneyTrack(trackId);

	if (!missionId) {
		return undefined;
	}

	if (!track) {
		return null;
	}

	return track.stories.find((mission) => mission.id === missionId) || null;
};
