import { Flex, SimpleGrid } from '@chakra-ui/react';
import { GeneralInfo } from './overview/GeneralInfo';
import { WeeklyActivity } from './overview/WeeklyActivity';
import { AllTimeCompletions } from './overview/AllTimeCompletions';
import { JourneyProgress } from './overview/JourneyProgress';
import { KeyImprovementStories } from './overview/KeyImprovementStories';

export const UserDetailsOverview = () => {
	return (
		<Flex direction={'column'} gap="7" my="6">
			<GeneralInfo />
			<SimpleGrid columns={2} gap={5}>
				<WeeklyActivity />
				<AllTimeCompletions />
			</SimpleGrid>
			<KeyImprovementStories />
			<JourneyProgress />
		</Flex>
	);
};
