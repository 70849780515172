import { useEffect } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	VStack,
	Text,
	Button,
	Image,
	useDisclosure,
	Divider,
	Box,
} from '@chakra-ui/react';
import { useTour } from '@reactour/tour';
import { useTranslation } from 'react-i18next';
import { useIsOnboarding } from '../../hooks/useIsOnboarding';
import { OnboardingFeature } from '../../types/Onboarding';

export const OnboardingModal = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { setIsOpen } = useTour();
	const isOnboarding = useIsOnboarding(OnboardingFeature.HOME_PAGE);
	const { t } = useTranslation('common');

	useEffect(() => {
		if (isOnboarding) {
			onOpen();
		}
	}, [isOnboarding, onOpen]);

	const onGetStarted = () => {
		onClose();
		setTimeout(() => {
			setIsOpen(true);
		}, 200);
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent maxW="450px" py={6} borderRadius={'24px'}>
				<ModalBody p={0}>
					<VStack spacing={4} align="center">
						<Image src="/logo-color.svg" alt="Jam Logo" w="24px" h="24px" />
						<Text mx={6} fontSize="xl" fontWeight="bold">
							{t('onboarding.welcome')}
						</Text>
						<Text mx={6} textAlign="center" color="gray.600">
							{t('onboarding.description')}
						</Text>
						<Divider />
						<Box width={'full'} px={6}>
							<Button
								px={6}
								w="full"
								colorScheme="brand"
								bg="brand.500"
								onClick={onGetStarted}
							>
								{t('onboarding.getStarted')}
							</Button>
						</Box>
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
