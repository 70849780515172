import { Flex, Stack, Text } from '@chakra-ui/react';
import { SchemaStoryResponseDto } from '@jam/api-sdk';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type MissionLineItemProps = {
	trackId: string;
	mission: SchemaStoryResponseDto;
	index: number;
};

export const MissionLineItem = ({
	mission,
	index,
	trackId,
}: MissionLineItemProps) => {
	const [isHovering, setIsHovering] = useState(false);
	const { t } = useTranslation('common');
	return (
		<Link to={`/track/${trackId}/mission/${mission.id}`}>
			<Flex
				borderTop={'1px'}
				borderColor={'#D9D9D9'}
				justifyContent={'space-between'}
				gap={2}
				p={3}
				cursor={'pointer'}
				_hover={{ bg: '#FAFAFA' }}
				onMouseEnter={() => setIsHovering(true)}
				onMouseLeave={() => setIsHovering(false)}
			>
				<Flex alignItems={'center'} gap={2}>
					<Flex
						w={'24px'}
						h={'24px'}
						border={'1px'}
						borderColor={'#D9D9D9'}
						borderRadius={'full'}
						justifyContent={'center'}
						alignItems={'center'}
					>
						<Text fontWeight={'500'} fontSize={'12px'} color={'#757575'}>
							{index + 1}
						</Text>
					</Flex>
					<Text>{mission.title}</Text>
				</Flex>
				<Stack divider={<Text>·</Text>} direction={'row'} gap={2}>
					{isHovering && (
						<Text fontWeight={'500'} color={'#4241E4'} fontSize={'14px'}>
							{t('view_details')}
						</Text>
					)}
					<Text color={'#757575'} fontSize={'14px'}>
						{`${mission.duration} ${t('mins')}`}
					</Text>
				</Stack>
			</Flex>
		</Link>
	);
};
