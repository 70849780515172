import {
	Box,
	Divider,
	Flex,
	HStack,
	SkeletonText,
	Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useUserProgress } from '../hooks/useUserProgress';

const LoadingState = () => {
	return (
		<Box
			bg="#FCFCFC"
			border={'1px'}
			borderColor={'#D9D9D9'}
			borderRadius={'24px'}
			p="5"
			ml="auto"
			minW={'350px'}
			w="fit-content"
		>
			<SkeletonText noOfLines={4} spacing="5" />
		</Box>
	);
};

export const CompletedLearnings = () => {
	const { t } = useTranslation('history');
	const progress = useUserProgress();
	if (progress === null) return null;
	if (progress === undefined) return <LoadingState />;
	return (
		<Box
			bg="#FCFCFC"
			border={'1px'}
			borderColor={'#D9D9D9'}
			borderRadius={'24px'}
			p="5"
			ml="auto"
			minW={'350px'}
			w="fit-content"
		>
			<HStack
				mt="3"
				spacing={'12px'}
				divider={<Divider height={'30px'} orientation="vertical" />}
				justifyContent={'space-around'}
			>
				<Flex
					justifyContent={'center'}
					textAlign={'center'}
					gap={'2'}
					direction={'column'}
				>
					<Text fontSize={'24px'}>{progress.storiesCompleted}</Text>
					<Text fontSize={'12px'} color={'#757575'}>
						{t('missions_mastered')}
					</Text>
				</Flex>
				<Flex
					justifyContent={'center'}
					textAlign={'center'}
					gap={'2'}
					direction={'column'}
				>
					<Text fontSize={'24px'}>{progress.tracksCompleted}</Text>
					<Text fontSize={'12px'} color={'#757575'}>
						{t('tracks_mastered')}
					</Text>
				</Flex>
			</HStack>
		</Box>
	);
};
