import { Flex } from '@chakra-ui/react';
import { ActivityPerUserTable } from './ActivityPerUserTable';
import { MostAndLeastActiveUsers } from './MostAndLeastActiveUsers';

export const UserActivity = () => {
	return (
		<Flex direction={'column'} py="10" gap="10">
			<MostAndLeastActiveUsers />
			<ActivityPerUserTable />
		</Flex>
	);
};
