import { AppLayout } from '../../common/components/AppLayout';
import {
	Flex,
	Tab,
	TabIndicator,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
} from '@chakra-ui/react';
import { UserActivity } from '../../features/dashboard/components/manager/user-activity/UserActivity';
import { useTranslation } from 'react-i18next';
import { TeamActivity } from '../../features/dashboard/components/manager/team-activity/TeamActivity';
import { UserWidgets } from '../../common/components/UserWidgets';

export const ManagerDashboard = () => {
	const { t } = useTranslation('dashboard');
	return (
		<AppLayout>
			<Flex mt={'-8px'} justifyContent={'space-between'}>
				<Text fontWeight={'medium'} fontSize="40px">
					{t('engagement_dashboard')}
				</Text>
				<UserWidgets />
			</Flex>
			<Text fontSize="16px" mt="5" color="#757575">
				{t('engagement_dashboard_description')}
			</Text>
			<Tabs mt="10" isLazy variant={'unstyled'}>
				<TabList borderBottom={0}>
					<Tab
						fontWeight={'medium'}
						_selected={{ color: '#4241E4' }}
						fontSize={'16px'}
					>
						{t('team_activity')}
					</Tab>
					<Tab
						fontWeight={'medium'}
						_selected={{ color: '#4241E4' }}
						fontSize={'16px'}
					>
						{t('user_activity')}
					</Tab>
				</TabList>
				<TabIndicator
					mt="-1.5px"
					height="2px"
					bg="#4241E4"
					borderRadius="1px"
				/>
				<TabPanels>
					<TabPanel px="0">
						<TeamActivity />
					</TabPanel>
					<TabPanel px="0">
						<UserActivity />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</AppLayout>
	);
};
