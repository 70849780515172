import { useState } from 'react';
import { AppLayout } from '../../common/components/AppLayout';
import { Salutation } from '../../features/home/components/Salutation';
import { Divider, Flex, Input } from '@chakra-ui/react';
import { useGetJourneyDetailsQuery } from '../../redux/api/cmsApi';
import { useGetUserJourneyContentAllocationQuery } from '../../redux/api/learnerApi';
import { TrackCard } from '../../features/manager-tracks/components/TrackCard';
import { useTranslation } from 'react-i18next';

export const ManagerTracksPage = () => {
	const { t } = useTranslation('common');
	const [search, setSearch] = useState('');

	const { data: userJourney } = useGetUserJourneyContentAllocationQuery();
	const { data: journey } = useGetJourneyDetailsQuery(
		userJourney?.journeyReferenceId || '',
		{
			skip: !userJourney?.journeyReferenceId,
		}
	);

	if (!journey || !userJourney) {
		return null;
	}
	const { tracks } = journey;
	const filtered = tracks.filter((track) => {
		const title = track.title.toLowerCase();
		return title.includes(search.toLowerCase());
	});
	return (
		<AppLayout>
			<Salutation text={t('tracks') ?? ''} />
			<Divider mb={5} />
			<Input
				maxWidth={'400px'}
				value={search}
				onChange={(e) => setSearch(e.target.value)}
				borderRadius={'8px'}
				placeholder={t('search_tracks') ?? ''}
			/>
			<Flex mt={6} direction={'column'} gap={4}>
				{filtered.map((track) => {
					return <TrackCard key={track.id} track={track} />;
				})}
			</Flex>
		</AppLayout>
	);
};
