import {
	Box,
	Button,
	Flex,
	Input,
	SkeletonText,
	Stack,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import {
	PendingInviteResponseDtoStatus,
	SchemaPendingInviteResponseDto,
} from '@jam/api-sdk';
import { Plus } from '@phosphor-icons/react';
import { createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip } from '../../../common/components/Chip';
import CustomTable from '../../../common/components/custom-table/CustomTable';
import { useGetInvitedUsersQuery } from '../../../redux/api/accountApi';
import { AddUserModal } from './AddUserModal';

const LoadingState = () => {
	return (
		<Flex role="loading" mt="5" direction={'column'} gap="6">
			<Box maxW="300px">
				<SkeletonText noOfLines={1} height={'30px'} />
			</Box>
			<SkeletonText w={'full'} noOfLines={5} spacing={'5'} />
		</Flex>
	);
};

export const PendingInvites = ({ orgId }: { orgId: string }) => {
	const { data, isLoading } = useGetInvitedUsersQuery({ orgId });
	const { t } = useTranslation(['settings', 'common']);

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [search, setSearch] = useState('');
	const columnHelper = createColumnHelper<SchemaPendingInviteResponseDto>();
	const columns = [
		columnHelper.accessor('email', {
			header: t('common:email').toString(),
			cell: ({ row }) => (
				<Text fontWeight={'medium'}>{row.original.email}</Text>
			),
		}),
		columnHelper.accessor('role', {
			header: t('common:role').toString(),
			cell: ({ row }) => {
				return (
					<Text
						px={'2'}
						py={'1'}
						border={'1px'}
						maxW="100px"
						borderRadius={'10px'}
						color={'#757575'}
						cursor={'not-allowed'}
						borderColor={'#757575'}
					>
						{row.original.role}
					</Text>
				);
			},
		}),
		columnHelper.display({
			header: t('common:status').toString(),
			cell: ({ row }) => (
				<Chip
					borderColor={
						row.original.status === PendingInviteResponseDtoStatus.pending
							? 'black'
							: 'gray.400'
					}
					textColor={
						row.original.status === PendingInviteResponseDtoStatus.pending
							? 'black'
							: 'gray.400'
					}
					py="2px"
					px="8px"
					bg={
						row.original.status === PendingInviteResponseDtoStatus.pending
							? '#EBF934'
							: 'gray.50'
					}
					text={
						row.original.status === PendingInviteResponseDtoStatus.pending
							? 'Pending'
							: 'Expired'
					}
				/>
			),
		}),
	];
	const customFilter = [{ id: 'email', value: search }];

	const userData = data ?? [];

	if (isLoading) {
		return <LoadingState />;
	}

	return (
		<Stack direction="column" spacing="5">
			<Text fontSize="2xl" fontWeight="semibold">
				{t('pending-invites')}
			</Text>
			<Flex gap="5">
				{userData.length > 0 && (
					<Input
						role="search"
						maxW={'500'}
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						borderRadius={'full'}
						placeholder={t('search-by-email') ?? ''}
					/>
				)}
				<Button
					id="add-user-button"
					leftIcon={<Plus size={15} />}
					borderRadius={'full'}
					type="submit"
					px="6"
					bgColor={'brand.500'}
					size="md"
					onClick={() => {
						onOpen();
					}}
				>
					{t('add-user')}
				</Button>
				<AddUserModal isOpen={isOpen} onClose={onClose} />
			</Flex>
			{userData.length > 0 && (
				<CustomTable<SchemaPendingInviteResponseDto>
					data={userData}
					columns={columns}
					customFilters={customFilter}
				/>
			)}
		</Stack>
	);
};
