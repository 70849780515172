import {
	SchemaPendingInviteResponseDto,
	SchemaUserResponseDto,
	WhoAmIResponseRole,
} from '@jam/api-sdk';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

const api = createApi({
	reducerPath: 'account-api',
	tagTypes: ['organization-users', 'pending-invites'],
	baseQuery: fetchBaseQuery({
		baseUrl: `${
			process.env
				.REACT_APP_SALES_ENABLEMENT_PLATFORM_NEST_API_BASE_URL as string
		}/api/v1/settings`,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).applicationState.accessToken;

			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}

			return headers;
		},
	}),
	endpoints: (builder) => ({
		getInvitedUsers: builder.query<
			SchemaPendingInviteResponseDto[],
			{ orgId: string }
		>({
			providesTags: ['pending-invites'],
			query: ({ orgId }) => `org/${orgId}/pending-invites`,
		}),
		getOrganizationUsers: builder.query<SchemaUserResponseDto[], void>({
			providesTags: ['organization-users'],
			query: () => 'org/users',
		}),
		disableUser: builder.mutation<void, { userId: string }>({
			invalidatesTags: ['organization-users'],
			query: ({ userId }) => ({
				url: 'disable-user',
				method: 'PATCH',
				body: {
					userId,
				},
			}),
		}),
		enableUser: builder.mutation<void, { userId: string }>({
			invalidatesTags: ['organization-users'],
			query: ({ userId }) => ({
				url: 'enable-user',
				method: 'PATCH',
				body: {
					userId,
				},
			}),
		}),

		completeOnboarding: builder.mutation<void, { feature: string }>({
			query: ({ feature }) => ({
				url: `complete-onboarding/${feature}`,
				method: 'PATCH',
			}),
		}),

		changeUserRole: builder.mutation<
			void,
			{ userId: string; orgId: string; role: WhoAmIResponseRole }
		>({
			invalidatesTags: ['organization-users'],
			query: ({ userId, orgId, role }) => ({
				url: `org/${orgId}/change-role`,
				method: 'PATCH',
				body: {
					userId,
					role,
				},
			}),
			async onQueryStarted({ userId, role }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					api.util.updateQueryData(
						'getOrganizationUsers',
						undefined,
						(draft) => {
							const user = draft.find((x) => x.id === userId);
							if (user) {
								user.role = role;
							}
						}
					)
				);
				try {
					await queryFulfilled;
				} catch {
					patchResult.undo();
				}
			},
		}),
		inviteUserToOrganization: builder.mutation<
			void,
			{ orgId: string; email: string; role: string }
		>({
			invalidatesTags: ['pending-invites'],
			query: ({ orgId, email, role }) => ({
				url: `org/${orgId}/invite-user`,
				method: 'POST',
				body: {
					email,
					role,
				},
			}),
		}),
		changeLanguage: builder.mutation<
			void,
			{
				language: string;
			}
		>({
			query: (body) => ({
				url: 'change-language',
				method: 'PATCH',
				body,
			}),
		}),
		changeAccountInfo: builder.mutation<
			void,
			{
				firstName: string;
				lastName: string;
			}
		>({
			query: (body) => ({
				url: 'change-name',
				method: 'PATCH',
				body,
			}),
		}),
		changeAccountEmail: builder.mutation<
			void,
			{
				newEmail: string;
			}
		>({
			query: (body) => ({
				url: 'change-email',
				method: 'PATCH',
				body,
			}),
		}),
		changePassword: builder.mutation<
			void,
			{
				password: string;
			}
		>({
			query: (body) => ({
				url: 'change-password',
				method: 'PATCH',
				body,
			}),
		}),
	}),
});

export const {
	useGetInvitedUsersQuery,
	useChangeLanguageMutation,
	useChangePasswordMutation,
	useChangeAccountInfoMutation,
	useChangeAccountEmailMutation,
	useGetOrganizationUsersQuery,
	useInviteUserToOrganizationMutation,
	useChangeUserRoleMutation,
	useDisableUserMutation,
	useEnableUserMutation,
	useCompleteOnboardingMutation,
} = api;
export default api;
