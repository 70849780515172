import {
	Box,
	Button,
	Flex,
	FormControl,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stack,
	Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { CreateLearnerStoryItemFeedbackDtoPreset } from '@jam/api-sdk';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAddUserItemFeedbackMutation } from '../../../redux/api/learnerApi';
import {
	NegativeFeedbackPerItem,
	NegativeFeedbackPerItemSchema,
} from '../types/FormTypes';

const FEEDBACK_ELEMENTS: Record<
	CreateLearnerStoryItemFeedbackDtoPreset,
	string
> = {
	NOT_FACTUALLY_CORRECT: 'not_factually_correct',
	DONT_UNDERSTAND: 'dont_understand',
	GRAMMAR_MISTAKE_OR_SPELLING_ERROR: 'grammar_mistake_or_spelling_error',
	TOO_COMPLICATED: 'too_complicated',
};

type NegativeUserFeedbackModalProps = {
	itemId: string;
	sessionId: string;
	isOpen: boolean;
	onClose: () => void;
};

export const NegativeUserFeedbackModal = ({
	isOpen,
	sessionId,
	onClose,
	itemId,
}: NegativeUserFeedbackModalProps) => {
	const { t } = useTranslation(['call', 'common']);
	const [addUserItemFeedback] = useAddUserItemFeedbackMutation();
	const { register, handleSubmit, getValues, setValue, watch } =
		useForm<NegativeFeedbackPerItem>({
			resolver: zodResolver(NegativeFeedbackPerItemSchema),
			defaultValues: {
				reasons: [],
				feedback: '',
			},
		});

	const onItemClicked = (reason: string) => {
		const reasons = getValues('reasons');
		if (reasons.includes(reason)) {
			setValue(
				'reasons',
				reasons.filter((r) => r !== reason)
			);
		} else {
			setValue('reasons', [...reasons, reason]);
		}
	};

	const onReset = () => {
		setValue('feedback', '');
		setValue('reasons', []);
	};

	const onSubmit = async (data: NegativeFeedbackPerItem) => {
		await addUserItemFeedback({
			storyItemReferenceId: itemId,
			sessionId,
			positive: false,
			content: data.feedback,
			preset: data.reasons,
		});
		onClose();
		onReset();
	};
	const reasons = watch('reasons');
	return (
		<Modal size={'lg'} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader fontSize={'16px'}>
					{t('negative_feedback_modal.title')}
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<Stack spacing={6}>
						<Flex flexWrap={'wrap'} gap="3">
							{Object.entries(FEEDBACK_ELEMENTS).map((element) => (
								<Box
									onClick={() => onItemClicked(element[0])}
									key={element[0]}
									cursor={'pointer'}
									color={reasons.includes(element[0]) ? '#4241E4' : '#757575'}
									border={'1px'}
									borderColor={
										reasons.includes(element[0]) ? '#4241E4' : '#D9D9D9'
									}
									px="2"
									bg={
										reasons.includes(element[0])
											? 'rgba(66, 65, 228, 1, 0.08)'
											: 'transparent'
									}
									borderRadius={'24px'}
								>
									<Text fontSize={'12px'}>
										{t(`negative_feedback_modal.${element[1]}`)}
									</Text>
								</Box>
							))}
						</Flex>

						<FormControl as="fieldset">
							<Input
								{...register('feedback')}
								placeholder={t('negative_feedback_modal.placeholder') ?? ''}
								size="sm"
							/>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button
						id="submit-negative-feedback-button"
						type="submit"
						borderRadius={'8px'}
						textColor={'white'}
						bg={reasons.length > 0 ? '#4241E4' : '#D9D9D9'}
						_hover={{ bg: reasons.length > 0 ? '#4241E4' : '#D9D9D9' }}
						colorScheme="blue"
						onClick={() => {
							if (reasons.length > 0) void handleSubmit(onSubmit)();
						}}
					>
						{t('common:submit')}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
