import { Flex, Text } from '@chakra-ui/react';
import { SchemaStoryResponseDto } from '@jam/api-sdk';
import { useState } from 'react';
import { Link } from 'react-router-dom';

type TaskLineItemProps = {
	task: SchemaStoryResponseDto['items_conversation_feedback'][0];
	index: number;
	missionId: string;
	trackId: string;
};

export const TaskLineItem = ({
	task,
	index,
	missionId,
	trackId,
}: TaskLineItemProps) => {
	const [isHovering, setIsHovering] = useState(false);

	return (
		<Link to={`/track/${trackId}/mission/${missionId}`}>
			<Flex
				borderTop={'1px'}
				borderColor={'#D9D9D9'}
				justifyContent={'space-between'}
				gap={2}
				p={3}
				cursor={'pointer'}
				_hover={{ bg: '#FAFAFA' }}
				onMouseEnter={() => setIsHovering(true)}
				onMouseLeave={() => setIsHovering(false)}
			>
				<Flex alignItems={'center'} gap={2}>
					<Flex
						w={'24px'}
						h={'24px'}
						border={'1px'}
						borderColor={'#D9D9D9'}
						borderRadius={'full'}
						justifyContent={'center'}
						alignItems={'center'}
					>
						<Text fontWeight={'500'} fontSize={'12px'} color={'#757575'}>
							{index + 1}
						</Text>
					</Flex>
					<Text>{task.scorecard_title}</Text>
				</Flex>
				{isHovering && (
					<Text fontWeight={'500'} color={'#4241E4'} fontSize={'14px'}>
						View Details
					</Text>
				)}
			</Flex>
		</Link>
	);
};
