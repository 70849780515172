import { SessionResponseDTOAnalysisItemsSolved } from '@jam/api-sdk';
import {
	useGetAllSessionsByUserIdQuery,
	useGetUserJourneyContentAllocationQuery,
} from '../../../redux/api/learnerApi';
import { useAuthInfo } from '@propelauth/react';
import { skipToken } from '@reduxjs/toolkit/query';

type UserProgress = {
	itemsCompleted: number;
	storiesCompleted: number;
	tracksCompleted: number;
};

export const useUserProgress = (): UserProgress | null | undefined => {
	const { user } = useAuthInfo();
	// get all the sessions
	const { data: calls, isLoading } = useGetAllSessionsByUserIdQuery(
		user?.userId ?? skipToken,
		{
			refetchOnMountOrArgChange: true,
		}
	);

	const { data: userJourney, isLoading: userJourneyIsLoading } =
		useGetUserJourneyContentAllocationQuery();

	if (isLoading || userJourneyIsLoading) return undefined;

	if (!userJourney) return null;
	const completedStories = userJourney.trackStatuses.flatMap(
		(trackStatus) => trackStatus.completedStoryIds
	);

	if (!calls) return null;

	const completedTracks = userJourney.trackStatuses.filter(
		(track) => track.hasFinished
	).length;

	const completedCalls = calls.filter((call) =>
		call.analysis?.items.every(
			(i) => i.solved === SessionResponseDTOAnalysisItemsSolved.solved
		)
	);

	// items completed
	const itemsCompleted = completedCalls
		.filter((cc) => completedStories.includes(cc.storyReferenceId))
		.reduce((acc, call) => acc + (call.analysis?.items.length || 0), 0);

	return {
		itemsCompleted,
		storiesCompleted: completedStories.length,
		tracksCompleted: completedTracks,
	};
};
