import { configureStore } from '@reduxjs/toolkit';
import accountApi from './api/accountApi';
import cmsApi from './api/cmsApi';
import dashboardApi from './api/dashboardApi';
import learnerApi from './api/learnerApi';
import reportApi from './api/reportApi';

import { applicationStateSlice } from './slice';

export function buildStore() {
	return configureStore({
		reducer: {
			applicationState: applicationStateSlice.reducer,
			[learnerApi.reducerPath]: learnerApi.reducer,
			[dashboardApi.reducerPath]: dashboardApi.reducer,
			[cmsApi.reducerPath]: cmsApi.reducer,
			[accountApi.reducerPath]: accountApi.reducer,
			[reportApi.reducerPath]: reportApi.reducer,
		},
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware()
				.concat(learnerApi.middleware)
				.concat(dashboardApi.middleware)
				.concat(cmsApi.middleware)
				.concat(accountApi.middleware)
				.concat(reportApi.middleware),
	});
}
const store = buildStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
