import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useGetSessionActivityQuery } from '../../../../../redux/api/dashboardApi';
import { ActiveUsersList } from './ActiveUsersList';

export const MostAndLeastActiveUsers = () => {
	const { t } = useTranslation('dashboard');
	const { data, error, isLoading, isFetching } = useGetSessionActivityQuery();

	if (isLoading || isFetching) return <div>Loading...</div>;

	if (error) return <div>Error fetching data</div>;

	if (!data) return <div>No data available</div>;

	return (
		<Box>
			<Text
				lineHeight={'30px'}
				color="#757575"
				fontWeight={'medium'}
				fontSize="24px"
			>
				{t('most_and_least_active_users')}
			</Text>
			<Flex direction={{ base: 'column', lg: 'row' }} mt="5" gap="5">
				{data.mostActive && (
					<ActiveUsersList
						type="most"
						completedSessionUserActivities={data.mostActive}
					/>
				)}
				{data.leastActive && (
					<ActiveUsersList
						type="least"
						completedSessionUserActivities={data.leastActive}
					/>
				)}
			</Flex>
		</Box>
	);
};
