import { useParams } from 'react-router-dom';
import { AppLayout } from '../../common/components/AppLayout';
import { BreadCrumbs } from '../../features/manager-tracks/components/BreadCrumbs';
import { TrackDetail } from '../../features/manager-tracks/components/TrackDetail';
import { useGetJourneyTrack } from '../../features/manager-tracks/hooks/useGetCurrentJourney';

export const ManagerTrackDetailsPage = () => {
	const { trackId } = useParams();
	const track = useGetJourneyTrack(trackId);

	if (!track) {
		return null;
	}
	return (
		<AppLayout>
			<BreadCrumbs
				backLink="/tracks"
				items={[
					{
						title: track.title,
						link: `/track/${track.id}`,
					},
				]}
			/>
			<TrackDetail />
		</AppLayout>
	);
};
