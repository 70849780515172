import { StepType } from '@reactour/tour';
import StepContent from './StepContent';

const commonStepConfig = {
	position: 'right' as const,
	styles: {
		popover: (props: {
			backgroundColor?: string;
			borderRadius?: string;
			color?: string;
		}) => ({
			...props,
			backgroundColor: '#4241E4',
			borderRadius: '16px',
			color: 'white',
		}),
		badge: () => ({
			display: 'none',
		}),
	},
};

export const OnboardingSteps: StepType[] = [
	{
		selector: '#training-center-section',
		content: <StepContent translationKey="onboarding.training_center" />,
		...commonStepConfig,
	},
	{
		selector: '#help-center-section',
		content: <StepContent translationKey="onboarding.help_center" />,
		...commonStepConfig,
	},
	{
		selector: '#next-story-start-button',
		content: <StepContent translationKey="onboarding.start_journey" />,
		...commonStepConfig,
	},
];

export const CallPageSteps: StepType[] = [
	{
		selector: '.feedback-tab',
		content: <StepContent translationKey="onboarding.feedback" />,
		...commonStepConfig,
	},

	{
		selector: '.thumbs-container',
		content: <StepContent translationKey="onboarding.analysis_thumbs" />,
		...commonStepConfig,
	},
];
